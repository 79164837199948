/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import Error from "components/error";
import SearchIcon from "components/icons/SearchIcon";
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import AssignmentActivityForm from "views/admin/ministry/components/AssignmentActivityForm";
import AssignmentMilestoneForm from "views/admin/ministry/components/AssignmentMilestoneForm";
import ExecutingBodyModal from "views/admin/ministry/components/ExecutingBodyModal";

function MilestoneForm({
  project,
  milestonesData = [],
  fetchMilestones,
  showActivities,
  setShowActivities,
}) {
  const [milestones, setMilestones] = useState(milestonesData);
  const [search, setSearch] = useState("");
  const [viewForm, setViewForm] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [selectedExBody, setSelectedExBody] = useState(null);

  const errorRef = useRef(null);
  const [{ loading: mileLoad, error: mileError }, postMile] = useAxios(
    {
      url: "/ministry_tasks/assignment-milestones/",
      method: "POST",
    },
    { manual: true }
  );
  const [{ loading: exBodyDelLoad, error: exBodyDelError }, delExBody] =
    useAxios(
      {
        url: `/ministry_tasks/assignment-milestones/`,
        method: "DELETE",
      },
      { manual: true }
    );

  const [{ loading: mileDelLoad, error: mileDelError }, delMile] = useAxios(
    {
      url: `/ministry_tasks/assignment-milestones/`,
      method: "DELETE",
    },
    { manual: true }
  );

  const handleMileSave = async (data, update) => {
    console.log("update", update);
    if (!project) return;
    let success = false;

    const mileStone = {
      assignment_milestone_name: data.milestoneName,
      description: data.milestoneDescription,
      end_date: data.milestoneEndDate,
      status: data.status,
      assigned_to: data.assignedTo,
      executing_body_id: data.executing_body_id,
    };
    try {
      await postMile({
        url: update
          ? `/ministry_tasks/assignment-milestones/${update}/`
          : "/ministry_tasks/assignment-milestones/",
        method: update ? "PUT" : "POST",
        data: mileStone,
      }).then(async (res) => {
        toast(`Milestone ${update ? "updated" : "created"} successfully`, {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        fetchMilestones().then(() => {
          success = true;
        });
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  const handleMileDelete = async (mileId) => {
    if (!mileId) return;
    let success = false;
    try {
      await delMile({
        url: `/ministry_tasks/assignment-milestones/${mileId}/`,
      }).then(async (res) => {
        setMilestones(
          milestones.filter((milestone) => milestone.id !== mileId)
        );
        toast("Milestone deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        fetchMilestones().then(() => {
          success = true;
        });
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  const handleExBodyDelete = async (exBodyId) => {
    if (!exBodyId) return;
    let success = false;
    try {
      await delExBody({
        url: `/ministry_tasks/executing-bodies/${exBodyId}/`,
      }).then(async (res) => {
        setMilestones(
          milestones.filter((milestone) => milestone.id !== exBodyId)
        );
        toast("Executing Body deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        fetchMilestones().then(() => {
          success = true;
        });
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  const handleSearch = () => {
    if (search === "") {
      setMilestones(milestonesData);
      return;
    }
    const filtered = milestonesData?.filter((milestone) =>
      milestone?.executing_body_name
        ?.toLowerCase()
        ?.includes(search?.toLowerCase())
    );
    setMilestones(filtered);
  };

  useEffect(() => {
    if (
      milestonesData &&
      milestonesData?.length > 0 &&
      milestones?.length === 0
    ) {
      setMilestones(milestonesData);
    }
    if (selectedMilestone) {
      setSelectedMilestone(
        milestones.find((milestone) => milestone.id === selectedMilestone.id)
      );
    }
  }, [milestonesData]);

  useEffect(() => {
    if (selectedExBody) {
      setViewForm(true);
    }
  }, [selectedExBody]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const error = mileError || exBodyDelError || mileDelError;
  const loading = mileLoad || exBodyDelLoad || mileDelLoad;

  return (
    <div className="flex gap-2">
      <div className="flex min-h-[65vh] w-full flex-grow flex-col justify-between rounded-lg bg-[#F2F9FFCC] px-4 dark:bg-navy-700">
        {viewForm && (
          <ExecutingBodyModal
            data={selectedExBody ? selectedExBody : project}
            update={selectedExBody ? true : false}
            refetch={fetchMilestones}
            setOpen={setViewForm}
          />
        )}
        <div>
          {!showActivities && (
            <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
                <SearchIcon />
                <input
                  type="text"
                  placeholder={
                    showActivities
                      ? "Search Activities"
                      : "Search Executing Bodies"
                  }
                  className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <button
                className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
                onClick={() => {
                  setViewForm(true);
                }}
              >
                {showActivities ? "Add Activity" : "Add Executing Body"}
              </button>
            </div>
          )}
          {error && <Error ref={errorRef} error={error} />}
          {/* Milestones */}
          {loading && <Loading />}
          {!loading &&
            !showActivities &&
            milestones.map((exBody, index) => (
              <AssignmentMilestoneForm
                key={index}
                index={index}
                data={exBody}
                handleMilestoneSave={handleMileSave}
                loading={loading}
                fetchMilestones={fetchMilestones}
                setShowActivities={setShowActivities}
                selectedMilestone={selectedMilestone}
                setSelectedMilestone={setSelectedMilestone}
                setSelectedExBody={setSelectedExBody}
                handleMileDelete={handleMileDelete}
                removeExBody={async (id) => {
                  if (id) {
                    handleExBodyDelete(id);
                  }
                  setMilestones(
                    milestones.filter((milestone, i) => i !== index)
                  );
                }}
              />
            ))}
          {!loading && showActivities && (
            <AssignmentActivityForm
              data={selectedMilestone?.id}
              refetch={fetchMilestones}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default MilestoneForm;
