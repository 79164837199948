/* eslint-disable react-hooks/exhaustive-deps */
import TimeIcon from "components/icons/TimeIcon";
import { IoDocument } from "react-icons/io5";

function AssignmentStatus({ project }) {
  return (
    <div className="mt-5 w-full rounded-lg bg-[#F2F9FFCC] py-5 px-2 font-poppins dark:bg-navy-700 md:min-w-[600px] lg:px-6 3xl:w-1/5">
      <p className="text-lg font-semibold text-gray-700 dark:text-white ">
        Assignment Details
      </p>
      <div className="mt-5 flex w-full flex-col lg:max-w-[80%] ">
        <div className="flex justify-between">
          <p className="text-sm text-gray-700 dark:text-white ">
            Progress
            <span className="ml-2 text-2xl font-bold text-[#485FE5]">
              {Number(project?.completion_percentage || 0).toFixed(2)}%
            </span>
          </p>
        </div>
        <div className="mt-2 flex h-3 w-full items-center rounded-full bg-white">
          <span
            className={`h-full rounded-full bg-[#485FE5]`}
            style={{
              width: `${Number(project?.completion_percentage || 0).toFixed(
                2
              )}%`,
            }}
          />
        </div>
      </div>
      <div className="mt-4 py-2 text-gray-700 dark:text-white ">
        {/* Created at */}
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <TimeIcon />
            <p>Created At</p>
          </div>
          <p className="rounded-lg bg-white py-2 px-4 text-gray-700 lg:min-w-[200px]">
            {new Date(project?.start_date).toDateString()}
          </p>
        </div>

        {/* Text details */}
        <div className="mt-6">
          <p>Name</p>
          <p className="w-full rounded-lg bg-white py-4 px-6 text-gray-700">
            {project?.project_name}
          </p>
        </div>

        <div className="mt-6 flex w-full justify-between">
          <div>
            <p>Start Date</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:px-6">
              {new Date(project?.start_date).toDateString()}
            </p>
          </div>
          <div>
            <p>End Date</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:px-6">
              {new Date(project?.end_date).toDateString()}
            </p>
          </div>
        </div>

        <div className="mt-4">
          <p>Description</p>
          <p className="mt-1 rounded-lg bg-white p-5 dark:text-gray-700">
            {project?.description}
          </p>
        </div>

        <div className="mt-4">
          <p>Objective</p>
          <p className="mt-1 rounded-lg bg-white p-5 dark:text-gray-700">
            {project?.objective}
          </p>
        </div>

        <div className="mt-4">
          <p>Files</p>
          {project?.files?.map((file) => (
            <div key={file.id} className="mt-3 flex items-start gap-5 ">
              <IoDocument color="#2F80ED" size={45} />
              <p className="mt-1 text-sm dark:text-white">
                {file.file.split("/").pop().split(".")[0]}
              </p>
              <a
                href={file.file}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer text-sm text-blueSecondary underline"
              >
                open
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AssignmentStatus;
