import Widget from "components/widget/Widget";
import Tasks from "components/icons/Tasks";
import CreativeBoxs from "components/icons/CreativeBoxs";
import CategoryIcon from "components/icons/CategoryIcon";
import useAxios from "axios-hooks";
import Card from "components/card";
import ComplexTable from "views/admin/ministry/components/ComplexTable";
import ProjectsYearCount from "views/admin/default/components/ProjectsYearCount";
import ProjectsByMonth from "views/admin/default/components/ProjectsByMonth";
import { useEffect, useState } from "react";

const assColumns = [
  {
    Header: "NO",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "assignment_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

const AssDashboard = () => {
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [yearRange, setYearRange] = useState([]);

  const [{ data: assData }] = useAxios(
    {
      url: "/dashboard/individual_assignment-stats/",
      method: "GET",
    },
    { useCache: false }
  );
  const [{ data: assignments }] = useAxios(
    {
      url: "/ministry_tasks/individual-assignments/",
      method: "GET",
    },
    { useCache: false }
  );

  const [{ data: projectsMonth }] = useAxios({
    url: "/dashboard/individual-assignment-by-month/",
    params: { year: year },
    method: "GET",
  });

  const [{ data: projectsYearCount }] = useAxios(
    {
      url: "/dashboard/individual-assignment-year-count/",
      method: "GET",
    },
    { useCache: false }
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const range = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
    setYearRange(range);
  }, []);

  return (
    <div className="flex-grow">
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
        <Widget
          icon={<CategoryIcon />}
          extra={"bg-[#7660F9]"}
          title={"Total Individual Assignments"}
          subtitle={assData?.total_individual_assignment || 0}
        />

        <Widget
          icon={<CreativeBoxs />}
          extra={"bg-[#37AC50]"}
          title={"Ongoing Individual Assignments"}
          subtitle={assData?.ongoing_individual_assignment || 0}
        />

        <Widget
          icon={<Tasks />}
          extra={"bg-[#C87325]"}
          title={"Completed Individual Assignments"}
          subtitle={assData?.completed_individual_assignment || 0}
        />
      </div>

      {/* Charts */}

      <div className="mt-5 w-full justify-between gap-5 md:flex">
        <ProjectsYearCount
          yearCount={projectsYearCount}
          title={"Assignments"}
        />
        <ProjectsByMonth
          title={"Assignments"}
          projectsMonth={projectsMonth}
          year={year}
          setYear={setYear}
          yearRange={yearRange}
        />
      </div>

      {/* Tables */}

      <div className="mt-5 grid grid-cols-1 gap-5 ">
        {/* Projects Table */}
        <Card extra="h-full rounded-lg bg-[#FAFAFA] p-5">
          <p className="mb-3 pl-5 text-xl font-[600] text-[#787486]">
            Individual Assignments
          </p>
          <ComplexTable
            columnsData={assColumns}
            tableData={assignments?.results || []}
          />
        </Card>
      </div>
    </div>
  );
};

export default AssDashboard;
