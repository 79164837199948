import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const location = useLocation();
  const { role } = useSelector((state) => state.auth);

  const handleRedirect = () => {
    if (
      role.name === "Team-Leader" &&
      !location.pathname.includes("/team-leader")
    ) {
      return <Navigate to="/team-leader/home" />;
    } else if (role.name === "Admin" && !location.pathname.includes("/admin")) {
      return <Navigate to="/admin/home" />;
    } else if (role.name === "CEO" && !location.pathname.includes("/ceo")) {
      return <Navigate to="/ceo/home" />;
    } else if (
      (role.name === "Expert" || role.name === "Project-Coordinator") &&
      !location.pathname.includes("/expert")
    ) {
      return <Navigate to="/expert/projects" />;
    } else if (
      role.name === "Higher-Official" &&
      !location.pathname.includes("/higher-official")
    ) {
      return <Navigate to="/higher-official/home" />;
    } else if (
      role.name === "Ministry" &&
      !location.pathname.includes("/ministry")
    ) {
      return <Navigate to="/ministry/home" />;
    } else if (
      role.name === "Dedicated-Assignee" &&
      !location.pathname.includes("/assinment-individuals")
    ) {
      return <Navigate to="/assinment-individuals/assignments" />;
    } else {
      return <Outlet />;
    }
  };

  return token ? (
    role && handleRedirect()
  ) : (
    <Navigate to="/auth/sign-in" state={{ from: location }} replace />
  );
};
export default RequireAuth;
