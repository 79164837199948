/* eslint-disable react-hooks/exhaustive-deps */
import AllProjects from "components/icons/AllProjects";
import CompletedProjects from "components/icons/CompletedProjects";
import PendingProjects from "components/icons/PendingProjects";
import { BsPlus } from "react-icons/bs";
import ProjectSidebar from "./components/ProjectSidebar";
import ComplexTable from "./components/ComplexTable";
import { useContext, useEffect, useState } from "react";
import SidebarModal from "components/popover/SidebarModal";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";
import { CeoRouteNameContext } from "layouts/ceo";
import toast from "react-hot-toast";
import { IoAlert, IoCheckmark } from "react-icons/io5";

const columnsDataComplex = [
  {
    Header: "NO",
    accessor: "id",
  },
  {
    Header: "Task Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function ProjectsList() {
  const path = window.location.pathname;
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const [search, setSearch] = useState("");
  const { setCurrentRoute, currentRoute } = useContext(CeoRouteNameContext);

  const [{ data: projects, loading, error }, fetchProjects] = useAxios(
    {
      url: "/main_app/projects/",
      params: {
        page: page || 1,
        search: search,
        status:
          activeTab === 0
            ? undefined
            : activeTab === 1
            ? "not_started"
            : activeTab === 2
            ? "ongoing"
            : "completed",
      },
      method: "GET",
      timeout: 10000,
    },
    { useCache: false, autoCancel: true }
  );

  const [{ loading: postLoading, error: postError }, post] = useAxios(
    {
      url: "/main_app/projects/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    },
    { manual: true }
  );

  const tabs = [
    { title: "All Tasks", icon: <AllProjects /> },
    {
      title: "Not Started",
      icon: <PendingProjects />,
    },
    { title: "Ongoing", icon: <PendingProjects /> },
    { title: "Completed", icon: <CompletedProjects /> },
  ];

  const activeColums =
    activeTab === 0
      ? columnsDataComplex.reduce((acc, item) => {
          if (item.Header === "Status") {
            return acc;
          }
          return [...acc, item];
        }, [])
      : columnsDataComplex;

  const fillterData = () => {
    setTableData(
      projects?.results
        ?.filter((item) => {
          if (activeTab === 1) {
            return item["status"] === "not_started";
          }
          if (activeTab === 2) {
            return item["status"] === "ongoing";
          }
          if (activeTab === 3) {
            return item["status"] === "completed";
          }

          return item;
        })
        .map((item, index) => {
          if (activeTab === 0) {
            const newItem = { ...item, action: 0 };
            return newItem;
          }
          item["action"] = 1;
          return item;
        })
    );
  };

  const handleCreateProject = async (formData) => {
    try {
      const response = await post(
        {
          data: formData,
          method: selectedProject ? "PUT" : "POST",
          url: selectedProject
            ? `/main_app/projects/${selectedProject.id}/`
            : "/main_app/projects/",
        },
        { manual: true }
      );
      if (response.status === 201 || response.status === 200) {
        toast.success(
          `Task ${selectedProject ? "updated" : "created"} successfully`,
          {
            icon: <IoCheckmark />,
            style: { color: "green" },
          }
        );
        await fetchProjects();
        setShowSidebar(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      const response = await post({
        method: "DELETE",
        url: `/main_app/projects/${id}/`,
      });
      if (response.status === 204) {
        toast.success("Task deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        await fetchProjects();
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred", {
        icon: <IoAlert size={30} />,
        style: { color: "red", alignItems: "start" },
        duration: 8000,
      });
    }
  };

  useEffect(() => {
    if (path === "/ceo/projects/create") {
      setShowSidebar(true);
    }
    return () => {};
  }, [path]);

  useEffect(() => {
    fillterData();
  }, [projects, activeTab]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Task List") {
      setCurrentRoute("Task List");
    }
    return () => {};
  }, [setCurrentRoute, currentRoute]);

  return (
    <div className="mt-5">
      {/* Add project drawer */}

      <SidebarModal
        bg={"bg-white"}
        closeBtn={
          <button
            id="close-drawer-navigation"
            tabIndex="-1"
            className="fixed right-0 top-1 z-50 h-20 w-20 rounded-full p-2 shadow-gray-800 transition-transform duration-150  dark:bg-gray-800 xl:h-20 xl:w-20 3xl:top-[48%] 3xl:right-[49%] 3xl:bg-white 3xl:shadow-2xl"
            onClick={() => {
              setShowSidebar(false);
              setSelectedProject(null);
              if (window.location.pathname.includes("create"))
                navigate("/ceo/projects");
            }}
          >
            <FiX className="mx-auto h-10 w-10 text-gray-600" />
          </button>
        }
        isOpen={showSidebar}
        setIsOpen={setShowSidebar}
        content={
          <ProjectSidebar
            // key={selectedProject ? selectedProject.id : Math.random()}
            handleCreateProject={handleCreateProject}
            loading={postLoading}
            error={postError}
            project={selectedProject}
          />
        }
      />

      <div className="mb-3 flex flex-col-reverse items-end justify-between lg:flex-row lg:items-center">
        <div className="my-3 w-full  border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400 lg:w-fit">
          <ul className="-mb-px flex-wrap gap-5 lg:flex lg:w-fit">
            {tabs.map((item, index) => (
              <li className="flex items-center lg:w-fit" key={index}>
                <p
                  className={`flex w-full cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex w-fit gap-2">
          <input
            type="text"
            placeholder="Search"
            className="h-14 flex-grow rounded-lg border border-gray-200 px-3 text-gray-900 placeholder:text-gray-900 lg:min-w-[250px]"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {activeTab === 0 && (
            <button
              className="flex h-14 flex-grow items-center gap-2 rounded-lg bg-[#485FE5] px-5 py-2  text-white"
              onClick={() => {
                setShowSidebar(true);
              }}
            >
              <BsPlus size={25} /> Add new
            </button>
          )}
        </div>
      </div>

      {error && <Error error={error} />}

      {!error && loading ? (
        <Loading />
      ) : (
        <>
          <ComplexTable
            columnsData={activeColums}
            tableData={tableData || []}
            setSelectedProject={(project) => {
              setSelectedProject(project);
              setShowSidebar(true);
            }}
            handleDeleteProject={handleDeleteProject}
          />
          <Paginate
            count={projects?.count || 0}
            page={page}
            setPage={(p) => {
              navigate(`/ceo/projects?page=${p}`);
              // setPage(p);
            }}
            next={() => {
              if (!projects?.next) {
                return;
              }
              navigate(`/ceo/projects?page=${page + 1}`);
              // setPage(page + 1);
            }}
            previous={() => {
              if (!projects?.previous) {
                return;
              }
              navigate(`/ceo/projects?page=${page - 1}`);
              // setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default ProjectsList;
