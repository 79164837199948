/* eslint-disable react-hooks/exhaustive-deps */
import AllProjects from "components/icons/AllProjects";
import CompletedProjects from "components/icons/CompletedProjects";
import PendingProjects from "components/icons/PendingProjects";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";
import ComplexTable from "./components/ComplexTable";
import { AssIndvRouteNameContext } from "layouts/assinment-individuals";

const columnsDataComplex = [
  {
    Header: "NO",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "assignment_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function Assignment() {
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const [search, setSearch] = useState("");
  const { setCurrentRoute, currentRoute } = useContext(AssIndvRouteNameContext);

  const [{ data: tasks, loading, error }] = useAxios(
    {
      url: "/ministry_tasks/individual-assignments/",
      params: {
        page: page || 1,
        search: search,
        status:
          activeTab === 0
            ? undefined
            : activeTab === 1
            ? "not_started"
            : activeTab === 2
            ? "ongoing"
            : "completed",
      },
      method: "GET",
    },
    { useCache: false }
  );

  const tabs = [
    { title: "All Assignments", icon: <AllProjects /> },
    {
      title: "Not Started",
      icon: <PendingProjects />,
    },
    { title: "Ongoing", icon: <PendingProjects /> },
    { title: "Completed", icon: <CompletedProjects /> },
  ];

  const activeColums = columnsDataComplex;

  const fillterData = () => {
    setTableData(
      tasks?.results
        ?.filter((item) => {
          if (activeTab === 1) {
            return item["status"] === "not_started";
          }
          if (activeTab === 2) {
            return item["status"] === "ongoing";
          }
          if (activeTab === 3) {
            return item["status"] === "completed";
          }

          return item;
        })
        .map((item, index) => {
          if (activeTab === 0) {
            const newItem = { ...item, action: 0 };
            return newItem;
          }
          item["action"] = 1;
          return item;
        })
    );
  };

  useEffect(() => {
    fillterData();
  }, [tasks, activeTab]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Assignments List") {
      setCurrentRoute("Assignments List");
    }
    return () => {};
  }, [setCurrentRoute, currentRoute]);

  return (
    <div className="mt-5">
      {/* Add project drawer */}

      <div className="mb-3 flex flex-col-reverse items-end justify-between lg:flex-row lg:items-center">
        <div className="my-3 w-full  border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400 lg:w-fit">
          <ul className="-mb-px flex-wrap gap-5 lg:flex lg:w-fit">
            {tabs.map((item, index) => (
              <li className="flex items-center lg:w-fit" key={index}>
                <p
                  className={`flex w-full cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    activeTab === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <input
          type="text"
          placeholder="Search"
          className="h-14 w-full rounded-lg border border-gray-200 px-3 text-gray-900 placeholder:text-gray-900 lg:w-1/5"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>

      {error && <Error error={error} />}

      {!error && loading ? (
        <Loading />
      ) : (
        <>
          <ComplexTable
            columnsData={activeColums}
            tableData={tableData || []}
            link="/assinment-individuals/assignments"
          />
          <Paginate
            count={tasks?.count}
            page={page}
            setPage={(p) => {
              navigate(`/assinment-individuals/assignmets?page=${p}`);
              // setPage(p);
            }}
            next={() => {
              if (!tasks?.next) {
                return;
              }
              navigate(`/assinment-individuals/assignmets?page=${page + 1}`);
              // setPage(page + 1);
            }}
            previous={() => {
              if (!tasks?.previous) {
                return;
              }
              navigate(`/assinment-individuals/assignmets?page=${page - 1}`);
              // setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default Assignment;
