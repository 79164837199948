import Widget from "components/widget/Widget";
import Tasks from "components/icons/Tasks";
import CreativeBoxs from "components/icons/CreativeBoxs";
import CategoryIcon from "components/icons/CategoryIcon";
import ProjectsByMonth from "views/admin/default/components/ProjectsByMonth";
import useAxios from "axios-hooks";
import Card from "components/card";
import ComplexTable from "views/admin/ceo/components/ComplexTable";
import AssDashboard from "views/admin/ministry/AssDashboard";
import { useEffect, useState } from "react";
import ProjectsYearCount from "views/admin/default/components/ProjectsYearCount";

export const complexColumns = [
  {
    Header: "NO",
    accessor: "id",
  },
  {
    Header: "Task Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },

  {
    Header: "Status",
    accessor: "status",
  },
];

const MinistryDashboard = () => {
  const [dashBoardType, setDashBoardType] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [yearRange, setYearRange] = useState([]);

  const [{ data: taskData }] = useAxios({
    url: "/dashboard/project-stats/",
    method: "GET",
  });
  const [{ data: projects }] = useAxios(
    {
      url: "/main_app/projects/",
      method: "GET",
    },
    { useCache: false }
  );

  const [{ data: projectsMonth }] = useAxios({
    url: "/dashboard/projects-by-month/",
    params: { year: year },
    method: "GET",
  });

  const [{ data: projectsYearCount }] = useAxios(
    {
      url: "/dashboard/project-year-count/",
      method: "GET",
    },
    { useCache: false }
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const range = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
    setYearRange(range);
  }, []);

  return (
    <div>
      <div className="flex justify-center">
        <button
          className={`mx-2 px-4 hover:text-blue-500 ${
            dashBoardType === 0 ? "text-blue-500 underline" : "text-gray-600"
          }`}
          onClick={() => setDashBoardType(0)}
        >
          Tasks Dashboard
        </button>
        <div className="mx-2 h-8 border-l border-gray-300"></div>
        <button
          className={`mx-2 px-4 py-2 hover:text-blue-500 ${
            dashBoardType === 1 ? "text-blue-500 underline" : "text-gray-600"
          }`}
          onClick={() => setDashBoardType(1)}
        >
          Assignments Dashboard
        </button>
      </div>
      <div className="mx-2 mb-5 h-1 border-t border-gray-300"></div>

      {/* Tasks */}
      {dashBoardType === 0 && (
        <div className="flex-grow">
          {/* Card widget */}
          <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
            <Widget
              icon={<CategoryIcon />}
              extra={"bg-[#C87325]"}
              title={"Total Tasks"}
              subtitle={taskData?.total_projects || 0}
            />

            <Widget
              icon={<CreativeBoxs />}
              extra={"bg-[#00A3C7]"}
              title={"Ongoing Tasks"}
              subtitle={taskData?.ongoing_projects || 0}
            />

            <Widget
              icon={<Tasks />}
              extra={"bg-[#37AC50]"}
              title={"Completed Tasks"}
              subtitle={taskData?.completed_projects || 0}
            />
          </div>

          {/* Charts */}

          <div className="mt-5 w-full justify-between gap-5 md:flex">
            <ProjectsYearCount yearCount={projectsYearCount} />
            <ProjectsByMonth
              projectsMonth={projectsMonth}
              year={year}
              setYear={setYear}
              yearRange={yearRange}
            />
          </div>

          {/* Tables */}

          <div className="mt-5 grid grid-cols-1 gap-5 ">
            {/* Projects Table */}
            <Card extra="h-full rounded-lg bg-[#FAFAFA] p-5">
              <p className="mb-3 pl-5 text-xl font-[600] text-[#787486]">
                Tasks Table
              </p>
              <ComplexTable
                columnsData={complexColumns}
                tableData={projects?.results || []}
              />
            </Card>
          </div>
        </div>
      )}
      {/* Assignments */}
      {dashBoardType === 1 && <AssDashboard />}
    </div>
  );
};

export default MinistryDashboard;
