import Loading from "components/loading";
import { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import {
  IoAddCircleOutline,
  IoChevronDownCircleOutline,
} from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import ActivityViewTableRow from "views/admin/ministry/components/ActivityViewTableRow";
import AssAcctivityRowForm from "views/admin/ministry/components/AssAcctivityRowForm";

function ActivitiesList({
  activity,
  mileId,
  handleActitvitySubmit,
  handleRemoveActivity,
  milestone,
  loading,
  getMile,
  isUserAssigned,
}) {
  const [showActivity, setShowActivity] = useState(false);
  const [editActivity, setEditActivity] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <DeleteModal
        key={activity?.id}
        id={`DeleteModal${mileId + activity?.id}`}
        handleCancel={() => {
          window.document
            .getElementById(`DeleteModal${mileId + activity?.id}`)
            .classList.replace("flex", "hidden");
        }}
        handleConfirm={() => {
          handleRemoveActivity(activity?.id);
          window.document
            .getElementById(`DeleteModal${mileId + activity?.id}`)
            .classList.replace("flex", "hidden");
        }}
      />
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          {milestone?.assignment_milestone_name || "Milestone Name"}
        </h1>

        {isUserAssigned && (
          <BsTrashFill
            className="mt-3 mr-3 h-4 w-4 cursor-pointer text-red-500"
            onClick={() => {
              window.document
                .getElementById(`DeleteModal${mileId + activity?.id}`)
                .classList.replace("hidden", "flex");
            }}
          />
        )}
      </div>
      {/*  */}
      {loading ? (
        <Loading />
      ) : (
        <div className="m-3">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              Activity
            </span>
            <div className={`flex items-center gap-2`}>
              {isUserAssigned && !activity?.id ? (
                <IoAddCircleOutline
                  className="h-5 w-5 cursor-pointer text-brand-600"
                  onClick={() => {
                    setShowActivity(false);
                    setShowActivityForm(!showActivityForm);
                  }}
                />
              ) : (
                isUserAssigned && (
                  <MdEdit
                    color="#687182"
                    className="h-4 w-4 cursor-pointer "
                    onClick={() => {
                      setEditActivity(true);
                      setShowActivity(false);
                      setShowActivityForm(!showActivityForm);
                    }}
                  />
                )
              )}
              {activity?.id && (
                <IoChevronDownCircleOutline
                  color="#687182"
                  className={`h-5 w-5 transform cursor-pointer ${
                    showActivity ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => {
                    setShowActivity(!showActivity);
                    setShowActivityForm(false);
                  }}
                />
              )}
            </div>
          </div>
          {showActivity && (
            <ActivityViewTableRow
              activity={activity}
              refetch={getMile}
              isUserAssigned={isUserAssigned}
            />
          )}

          <AssAcctivityRowForm
            edit={editActivity}
            setEdit={setEditActivity}
            data={activity}
            setShowMilestoneForm={setShowActivityForm}
            showMilestoneForm={showActivityForm}
            handleSave={async (formData, edit) => {
              handleActitvitySubmit(formData, edit);
              !edit && handleRemoveActivity(null);
            }}
          />
        </div>
      )}
    </div>
  );
}
export default ActivitiesList;
