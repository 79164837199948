/* eslint-disable react-hooks/exhaustive-deps */
import { BsPlus } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import SidebarModal from "components/popover/SidebarModal";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";
import { CeoRouteNameContext } from "layouts/ceo";
import toast from "react-hot-toast";
import { IoAlert, IoCheckmark } from "react-icons/io5";
import DepartmentSidebar from "views/admin/ceo/components/DepartmentSidebar";
import ComplexTableDepartments from "views/admin/ceo/components/ComplexTableDepartments";

const columnsDataComplex = [
  {
    Header: "NO",
    accessor: "id",
  },
  {
    Header: "Department Name",
    accessor: "department_name",
  },
  {
    Header: "Description",
    accessor: "department_description",
  },
  {
    Header: "Action",
    accessor: "",
  },
];

function DepartmentsList() {
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [view, setView] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const { setCurrentRoute, currentRoute } = useContext(CeoRouteNameContext);

  const [{ data: departments, loading, error }, fetchDeps] = useAxios(
    {
      url: "/account/departments/",
      params: { page: page || 1 },
      method: "GET",
    },
    { useCache: false }
  );

  const [{ loading: postLoading }, post] = useAxios(
    {
      url: "/account/departments/",
      method: "POST",
    },
    { manual: true }
  );

  // const handleSearch = (e) => {
  //   const value = e.target.value.toLowerCase();
  //   if (value?.length < 3) {
  //     return setTableData(departments?.results || []);
  //   }

  //   const result = departments?.results?.filter((item) => {
  //     return (
  //       item.project_name?.toLowerCase().includes(value) ||
  //       item.start_date?.toLowerCase().includes(value) ||
  //       item.end_date?.toLowerCase().includes(value) ||
  //       item.project_priority?.toLowerCase().includes(value) ||
  //       item.region_name?.toLowerCase().includes(value) ||
  //       item.execution_plan_status?.toLowerCase().includes(value)
  //     );
  //   });
  //   setTableData(result);
  // };

  const handleCreateDep = async (data) => {
    try {
      const res = await post({
        data,
        method: selectedProject ? "PUT" : "POST",
        url: selectedProject
          ? `/account/departments/${selectedProject.id}/`
          : "/account/departments/",
      });
      if (res.status === 201 || res.status === 200) {
        toast(
          <div className="flex items-center gap-2">
            <IoCheckmark className="text-2xl text-green-500" />
            <p>
              Department {selectedProject ? "updated" : "created"} successfully
            </p>
          </div>
        );
        fetchDeps();
        setShowSidebar(false);
      }
    } catch (error) {
      console.log(error);
      toast(
        <div className="flex items-center gap-2">
          <IoAlert className="text-2xl text-red-500" />
          <p>Failed to {selectedProject ? "update" : "create"} department</p>
        </div>
      );
    }
  };

  const handleDeleteDep = async (id) => {
    try {
      const res = await post({
        url: `/account/departments/${id}/`,
        method: "DELETE",
      });
      if (res.status === 204) {
        toast.success(
          <div className="flex items-center gap-2">
            <IoCheckmark className="text-2xl text-green-500" />
            <p>Department deleted successfully</p>
          </div>
        );
        fetchDeps();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        <div className="flex items-center gap-2">
          <IoAlert className="text-2xl text-red-500" />
          <p>Failed to delete department</p>
        </div>
      );
    }
  };

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Committee List") {
      setCurrentRoute("Committee List");
    }
    return () => {};
  }, [setCurrentRoute, currentRoute]);

  useEffect(() => {
    if (!departments) {
      return;
    }
    setTableData(departments?.results || []);
    return () => {};
  }, [departments]);

  return (
    <div className="mt-5">
      {/* Add project drawer */}

      <SidebarModal
        bg={"bg-white"}
        closeBtn={
          <button
            id="close-drawer-navigation"
            tabIndex="-1"
            className="fixed right-0 top-1 z-50 h-14 w-14 rounded-full p-2 shadow-gray-800 transition-transform duration-150  dark:bg-gray-800 xl:h-20 xl:w-20 3xl:top-[48%] 3xl:right-[49%] 3xl:bg-white 3xl:shadow-2xl"
            onClick={() => {
              setShowSidebar(false);
              setSelectedProject(null);
              setView(false);
            }}
          >
            <FiX className="mx-auto h-10 w-10 text-gray-600" />
          </button>
        }
        isOpen={showSidebar}
        setIsOpen={setShowSidebar}
        content={
          <DepartmentSidebar
            loading={postLoading}
            handleCreateDep={handleCreateDep}
            selectedProject={selectedProject}
            view={view}
          />
        }
      />

      <div className="mb-3 flex flex-col-reverse items-end justify-end lg:flex-row lg:items-center">
        <button
          className="flex h-fit items-center gap-2 rounded-lg bg-[#485FE5] px-7 py-2 text-sm text-white"
          onClick={() => {
            setShowSidebar(true);
          }}
        >
          <BsPlus size={25} /> Add new
        </button>
      </div>

      {error && <Error error={error} />}

      {!error && loading ? (
        <Loading />
      ) : (
        <>
          {departments && (
            <ComplexTableDepartments
              columnsData={columnsDataComplex}
              tableData={tableData || []}
              selectedProject={selectedProject}
              setSelectedProject={(project, view = false) => {
                setSelectedProject(project);
                setView(view);
                setShowSidebar(true);
              }}
              handleDeleteProject={handleDeleteDep}
            />
          )}
          <Paginate
            count={departments?.count}
            page={page}
            setPage={(p) => {
              navigate(`/ceo/commitee?page=${p}`);
              // setPage(p);
            }}
            next={() => {
              if (!departments?.next) {
                return;
              }
              navigate(`/ceo/commitee?page=${page + 1}`);
              // setPage(page + 1);
            }}
            previous={() => {
              if (!departments?.previous) {
                return;
              }
              navigate(`/ceo/commitee?page=${page - 1}`);
              // setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default DepartmentsList;
