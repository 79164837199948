/* eslint-disable no-empty-pattern */
import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import StatusDropdown from "components/dropdown/StatusDropdown";
import AddIconFilled from "components/icons/AddIconFilled";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import ActivityViewModal from "components/popover/ActivityViewModal";
import StatusShow from "components/StatusShow";
import { stringifyError } from "helpers/generateFuns";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoAlert, IoCheckmark } from "react-icons/io5";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import SubActivityModal from "views/admin/expert/components/SubActivityModal";
import { MilestoneContext } from "views/admin/expert/ProjectDetails";

function SubActivityRow({ subActivity, padding = "" }) {
  const [showMoreActivity, setShowMoreActivity] = useState(false);
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const { fetchMilestones } = useContext(MilestoneContext);
  const [{}, updateActivity] = useAxios(
    {
      url: `/main_app/activities/${subActivity.id}`,
      method: "DELETE",
    },
    { manual: true }
  );

  const toggleShowMore = () => {
    setShowMoreActivity(!showMoreActivity);
  };

  const handleDelete = async () => {
    try {
      await updateActivity().then(async () => {
        await fetchMilestones();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateAct = async (data, config) => {
    try {
      await updateActivity({
        url: config.url,
        method: "PUT",
        headers: config.headers || {
          "Content-Type": "application/json",
        },
        data: data,
      }).then(async () => {
        toast("Activity updated successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        fetchMilestones();
      });
    } catch (error) {
      const errorMsg = stringifyError(error);
      toast(
        `An error occurred 
        ${errorMsg}`,
        {
          icon: <IoAlert size={30} />,
          style: { color: "red", alignItems: "start" },
          duration: 8000,
        }
      );
    }
  };

  const handleStatusChange = async (status) => {
    if (status?.status) {
      const formdata = new FormData();
      formdata.append("completion_file", status?.file);
      formdata.append("actual_start_date", status?.startDate);
      formdata.append("actual_end_date", status?.endDate);
      formdata.append("actual_sub_activity_budget", status?.budget);
      formdata.append("status_memo", status?.status_memo);

      updateAct(formdata, {
        url: `/main_app/sub_activities/${subActivity.id}/status/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return;
    }

    updateAct(
      {
        ...subActivity,
        status: status.status,
        status_memo: status.status_memo,
      },
      { url: `/main_app/sub_activities/${subActivity.id}/` }
    );
  };

  return (
    <>
      <tr
        className={`table-row ${
          showMoreActivity
            ? "bg-[#EDEDFC] dark:bg-navy-900 "
            : "bg-gray-50 dark:bg-navy-900"
        }`}
      >
        <td colSpan={2}>
          {viewForm && (
            <SubActivityModal
              activity={subActivity}
              update={update}
              setOpen={setViewForm}
            />
          )}
          <div
            className={`relative ml-10 flex w-[150px] max-w-[300px] ${padding}`}
          >
            <div className=" flex items-center justify-between gap-3 text-start">
              {showMoreActivity ? (
                <ChevronDownOutline
                  onClick={toggleShowMore}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              ) : (
                <ChevronRightOutline
                  onClick={toggleShowMore}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              )}
              <p className="dark:text-white">{subActivity?.activity_name}</p>
            </div>
          </div>
        </td>
        <td className="">
          <div className="flex justify-center">
            <p className="w-[110px] truncate text-center dark:text-white">
              {subActivity?.activity_description}
            </p>
          </div>
        </td>
        <td>
          {viewForm && (
            <SubActivityModal
              activity={subActivity}
              key={subActivity?.id}
              update={update}
              setOpen={setViewForm}
            />
          )}
          {subActivity?.status !== "completed" && (
            <div
              className="flex cursor-pointer justify-center"
              onClick={() => {
                setViewForm(true);
              }}
            >
              <AddIconFilled className="h-8 w-8 " />
            </div>
          )}
        </td>
        <td className="flex justify-center">
          <StatusDropdown
            pos={"-left-28"}
            button={
              <StatusShow status={subActivity?.status}>
                <FaChevronDown size={10} className="cursor-pointer" />
              </StatusShow>
            }
            onSubmit={(value) => handleStatusChange(value, subActivity.id)}
          />
        </td>

        <td>
          <div className="flex w-full items-center justify-center gap-1">
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
              {subActivity?.start_date}
            </p>
            <BsChevronRight size={10} />
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
              {subActivity?.end_date}
            </p>
          </div>
        </td>
        <td>
          <p>{subActivity?.activity_budget}</p>
        </td>
        <td>
          {view && (
            <ActivityViewModal
              id={"activity" + subActivity.id}
              data={subActivity}
              setOpen={setView}
            />
          )}
          <DeleteModal
            id={"deleteSubActivity" + subActivity.id}
            handleConfirm={() => {
              handleDelete();
              window.document
                .getElementById(`deleteSubActivity${subActivity.id}`)
                .classList.replace("flex", "hidden");
            }}
            handleCancel={() => {
              window.document
                .getElementById(`deleteSubActivity${subActivity.id}`)
                .classList.replace("flex", "hidden");
            }}
          />
          <Dropdown
            button={<HiDotsVertical className="cursor-pointer" />}
            id={`dropdown${subActivity.id}`}
            classNames={"top-5 min-w-max -left-24"}
            children={
              <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setView(true);
                  }}
                >
                  View
                </p>
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setUpdate(true);
                    setViewForm(true);
                  }}
                >
                  Edit
                </p>
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    window.document
                      .getElementById(`deleteSubActivity${subActivity.id}`)
                      .classList.replace("hidden", "flex");
                  }}
                >
                  Delete
                </p>
              </div>
            }
          />
        </td>
      </tr>
      {showMoreActivity &&
        subActivity?.sub_activities?.map((subActivity) => (
          <SubActivityRow
            key={subActivity.id}
            subActivity={subActivity}
            padding="pl-5"
          />
        ))}
    </>
  );
}
export default SubActivityRow;
