/* eslint-disable react-hooks/exhaustive-deps */
import { FiX } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import { MilestoneContext } from "views/admin/expert/ProjectDetails";
import StatusShow from "components/StatusShow";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";

function SubDeliverableModal({ deliverable, update, setOpen }) {
  const [deliverableName, setDeliverableName] = useState("");
  const [deliverableDesc, setDeliverableDesc] = useState("");
  const [deliverableStatus, setDeliverableStatus] = useState("not_started");
  const [deliverableStart, setDeliverableStart] = useState("");
  const [deliverableEnd, setDeliverableEnd] = useState("");
  const [deliverableUnit, setDeliverableUnit] = useState("");
  const [target, setTarget] = useState("");
  const { fetchMilestones } = useContext(MilestoneContext);

  const [{ loading, error }, postActivity] = useAxios(
    {
      url: "/main_app/deliverables/",
      method: update ? "PUT" : "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!deliverable.project_milestone && !deliverable?.id) return;
    let success = false;
    const data = {
      deliverable_name: deliverableName,
      deliverable_description: deliverableDesc,
      status: deliverableStatus,
      start_date: deliverableStart,
      end_date: deliverableEnd,
      measurement_unit: deliverableUnit,
      target: target,
      parent_deliverable: deliverable?.id,
      activity: deliverable.activity,
    };
    try {
      await postActivity({
        url: update
          ? `/main_app/deliverables/${deliverable.id}/`
          : "/main_app/deliverables/",
        data: data,
      }).then(async () => {
        await fetchMilestones();
        toast(`Sub deliverable created successfully`, {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        setOpen(false);
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  useEffect(() => {
    if (update && deliverable) {
      setDeliverableName(deliverable.deliverable_name);
      setDeliverableDesc(deliverable.deliverable_description);
      setDeliverableStatus(deliverable.status);
      setDeliverableStart(deliverable.start_date);
      setDeliverableEnd(deliverable.end_date);
      setDeliverableUnit(deliverable.measurement_unit);
      setTarget(deliverable.status_memo);
    } else {
      setDeliverableName("");
      setDeliverableDesc("");
      setDeliverableStatus("not_started");
      setDeliverableStart("");
      setDeliverableEnd("");
      setDeliverableUnit("");
      setTarget("");
    }
  }, [update, deliverable]);

  return (
    <div
      id={`SubtaskModalForm${deliverable.id}`}
      tabIndex="-1"
      className="fixed inset-0 z-40 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative w-[900px] rounded-lg bg-white dark:bg-navy-800">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            setOpen(false);
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="flex flex-col px-6 pt-8 text-start lg:px-12">
          <h1 className="text-3xl font-semibold text-gray-900">
            {update ? "Update Sub Deliverable" : "Add Sub Deliverable"}
          </h1>
          <p className=" text-gray-700">
            {deliverable?.deliverable_name || "Deliverable name"}
          </p>
        </div>
        {error && <Error error={error} />}
        {loading && <Loading />}
        {!loading && (
          <div className="flex flex-col px-6 pb-6 text-start lg:px-12">
            <form
              id={`SubtaskForm${deliverable.id}`}
              onSubmit={handleSubmit}
              className="flex flex-col items-stretch justify-between rounded-lg bg-white py-2 text-start text-gray-700 lg:flex-row lg:gap-10"
            >
              {/* left side */}
              <div className="flex-grow">
                <div className="mt-4 flex items-center justify-between">
                  <div>
                    <h1>
                      Name <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Write a name</p>
                  </div>
                  <input
                    id="deliverableName"
                    type="text"
                    placeholder="Deliverable Name"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setDeliverableName(e.target.value)}
                    value={deliverableName}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Description</h1>
                    <p className="text-xs text-gray-500">Short Description</p>
                  </div>
                  <textarea
                    id="deliverableDescription"
                    placeholder="Deliverable Description"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setDeliverableDesc(e.target.value)}
                    rows={3}
                    value={deliverableDesc}
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1 className="">Measurement Unit</h1>
                  </div>
                  <input
                    id="deliverableUnit"
                    type="text"
                    placeholder="Measurement Unit"
                    className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2"
                    onChange={(e) => setDeliverableUnit(e.target.value)}
                    value={deliverableUnit}
                  />
                </div>
              </div>
              {/* Right side */}
              <div className="min-w-[42%] flex-grow">
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Target</h1>
                    <p className="text-xs text-gray-500">Write a target</p>
                  </div>
                  <input
                    id="deliverableStatusMemo"
                    type="text"
                    placeholder="Target"
                    className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2 dark:bg-navy-700"
                    onChange={(e) => setTarget(e.target.value)}
                    value={target}
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      Start Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <input
                    id="deliverableStartDate"
                    type="date"
                    placeholder="Start Date"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setDeliverableStart(e.target.value)}
                    value={deliverableStart}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      End Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <input
                    id="deliverableEndDate"
                    type="date"
                    placeholder="Start Date"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setDeliverableEnd(e.target.value)}
                    value={deliverableEnd}
                    required
                  />
                </div>

                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Status</h1>
                    <p className="text-xs text-gray-500">Choose status</p>
                  </div>
                  <StatusShow status={deliverableStatus} />
                </div>
              </div>
            </form>
            <button
              type="submit"
              form={`SubtaskForm${deliverable.id}`}
              className="self-end rounded-lg bg-blue-600 px-10 py-3 text-white"
            >
              Save
            </button>
            {/*  */}
          </div>
        )}
      </div>
    </div>
  );
}
export default SubDeliverableModal;
