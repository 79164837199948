import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import DeliverableViewTableRow from "views/admin/ceo/components/DeliverableViewTableRow";
import SubActivityViewTableRow from "views/admin/ceo/components/SubActivityViewTableRow";
import ActivityRowCard from "views/admin/expert/components/ActivityRowCard";

function DeliverablesList({
  data,
  setShowDeliverables,
  setSelectedActivity,
  activityName,
}) {
  const size = useWindowSize();
  const [showActivity, setShowActivity] = useState(false);
  const [showMilestone, setShowMilestone] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [shoeChildDel, setShowChildDel] = useState(false);

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          {activityName}
        </h1>
      </div>
      {/*  */}
      <div className="mt-3">
        <div className="flex items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
          <span className="text-sm text-gray-700 dark:text-gray-900 ">
            Deliverable
          </span>
          <div className={`flex gap-1`}>
            <IoChevronDownCircleOutline
              color="#687182"
              className={`h-5 w-5 transform cursor-pointer ${
                showMilestone ? "rotate-180" : "rotate-0"
              }`}
              onClick={() => {
                setShowMilestone(!showMilestone);
              }}
            />
          </div>
        </div>
        {showMilestone && (
          <DeliverableViewTableRow
            data={data}
            setShowChildDel={setShowChildDel}
            showChileDel={shoeChildDel}
            isCeo={true}
          />
        )}
        {/*  */}
        <div className="mb-2 mt-3 rounded-lg 2xl:ml-14">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2 dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              Sub Activities
            </span>
            <div className={`flex gap-1`}>
              <IoChevronDownCircleOutline
                color="#687182"
                className={`h-5 w-5 transform cursor-pointer ${
                  showActivity ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setShowActivity(!showActivity);
                  setShowActivityForm(false);
                }}
              />
            </div>
          </div>
          <div
            className={`${
              showActivity === true || showActivityForm === true
                ? "block"
                : "hidden"
            } px-1.5 py-2 text-gray-700`}
          >
            <div className="flex flex-col items-end text-center font-poppins text-sm text-[#707070] dark:text-white">
              {size?.width <= 720 ? (
                <div className="w-full">
                  {data?.activities?.map((activity, index) => {
                    return <ActivityRowCard activity={activity} key={index} />;
                  })}
                </div>
              ) : (
                <table className="w-full table-auto rounded bg-white dark:bg-navy-900">
                  <thead>
                    <tr className="bg-[#F7F9FCCC] text-[#687182]">
                      <th></th>

                      <th className="text-start font-normal">
                        <p className="ml-4 max-w-[200px] truncate">Name</p>
                      </th>
                      <th className="font-normal">Description</th>
                      <th className="">
                        <div className="flex items-center justify-center gap-2 font-normal uppercase">
                          <p>status</p>
                        </div>
                      </th>
                      <th>
                        <div className="flex items-center justify-center gap-2 font-normal uppercase">
                          <p>Project timeline</p>
                        </div>
                      </th>
                      <th className="font-normal">Budget Estimation</th>
                      <th></th>
                    </tr>
                  </thead>
                  {data?.sub_activities?.map((activity, index) => {
                    return (
                      <SubActivityViewTableRow
                        key={index}
                        data={activity}
                        setShowDeliverables={setShowDeliverables}
                        setSelectedActivity={setSelectedActivity}
                      />
                    );
                  })}
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default DeliverablesList;
