import Widget from "components/widget/Widget";
import Tasks from "components/icons/Tasks";
import CreativeBoxs from "components/icons/CreativeBoxs";
import CategoryIcon from "components/icons/CategoryIcon";
import ProjectsByMonth from "views/admin/default/components/ProjectsByMonth";
import useAxios from "axios-hooks";
import Card from "components/card";
import ComplexTable from "views/admin/ceo/components/ComplexTable";
import { complexColumns } from "views/admin/ceo";
import { useEffect, useState } from "react";
import ProjectsYearCount from "views/admin/default/components/ProjectsYearCount";

const TeamLeaderDashboard = () => {
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [yearRange, setYearRange] = useState([]);

  const [{ data }] = useAxios({
    url: "/dashboard/project-stats/",
    method: "GET",
  });
  const [{ data: projects }] = useAxios(
    {
      url: "/main_app/projects/",
      method: "GET",
    },
    { useCache: false }
  );

  const [{ data: projectsYearCount }] = useAxios(
    {
      url: "/dashboard/project-year-count/",
      method: "GET",
    },
    { useCache: false }
  );

  const [{ data: projectsMonth }] = useAxios({
    url: "/dashboard/projects-by-month/",
    params: { year: year },
    method: "GET",
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const range = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);
    setYearRange(range);
  }, []);

  return (
    <div className="mt-5 justify-between gap-5 3xl:flex">
      {/* Left Side */}
      <div className="flex-grow">
        {/* Card widget */}
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <Widget
            icon={<CategoryIcon />}
            extra={"bg-[#C87325]"}
            title={"Total Tasks"}
            subtitle={data?.total_projects || 0}
          />

          <Widget
            icon={<CreativeBoxs />}
            extra={"bg-[#00A3C7]"}
            title={"Ongoing Tasks"}
            subtitle={data?.ongoing_projects || 0}
          />

          <Widget
            icon={<Tasks />}
            extra={"bg-[#37AC50]"}
            title={"Completed Tasks"}
            subtitle={data?.completed_projects || 0}
          />
        </div>

        {/* Charts */}

        <div className="mt-5 w-full justify-between gap-5 md:flex">
          <ProjectsYearCount yearCount={projectsYearCount} />
          <ProjectsByMonth
            projectsMonth={projectsMonth}
            year={year}
            setYear={setYear}
            yearRange={yearRange}
          />
        </div>

        {/* Tables */}
        <div className="mt-5 grid grid-cols-1 gap-5 ">
          {/* Projects Table */}
          <Card extra="h-full rounded-lg bg-[#FAFAFA] p-5">
            <p className="mb-3 pl-5 text-xl font-[600] text-[#787486]">
              Tasks Table
            </p>
            <ComplexTable
              columnsData={complexColumns}
              tableData={projects?.results || []}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TeamLeaderDashboard;
