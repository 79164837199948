/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/ceo" ||
        route.layout === "/expert" ||
        route.layout === "/team-leader" ||
        route.layout === "/higher-official" ||
        route.layout === "/ministry" ||
        route.layout === "/assinment-individuals" ||
        route.layout === "/rtl"
      ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="relative mb-3 flex hover:cursor-pointer">
              <li
                className={`my-[3px] mx-8 flex flex-grow cursor-pointer items-center px-5 py-2 ${
                  activeRoute(route.path) === true
                    ? "rounded-lg bg-[#5030E514] dark:bg-brand-400"
                    : ""
                } `}
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "fill-brand-500 font-bold text-brand-500 dark:fill-white dark:text-white"
                      : "fill-gray-600 font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-blue-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {/* {activeRoute(route.path) ? (
                <div className="absolute right-0 top-1 h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : null} */}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
