import useAxios from "axios-hooks";
import HigherOffDropdown from "components/dropdown/HigherOffDropdown";
import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";

function DepartmentSidebar({
  loading,
  handleCreateDep,
  selectedProject,
  view,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [{ data: users }] = useAxios({
    url: "/account/user_list",
    method: "GET",
    params: {
      no_pagination: true,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const users = selectedUsers.map((user) => user.profile.user);
    handleCreateDep({
      department_name: name,
      department_description: description,
      users,
    });
  };

  const filteredUsers = (list) => {
    return list.filter(
      (user) => user.profile !== null && user.roles[0]?.role.name !== "Admin"
    );
  };

  useEffect(() => {
    if (selectedProject && users) {
      const selected = filteredUsers(users).filter((user) => {
        return selectedProject.users?.find(
          (selected) => selected === user.profile.user
        );
      });
      console.log(selected);
      setSelectedUsers(selected || []);
      setName(selectedProject?.department_name);
      setDescription(selectedProject?.department_description);
    } else {
      setSelectedUsers([]);
      setName("");
      setDescription("");
    }
    // eslint-disable-next-line
  }, [selectedProject, users]);

  return (
    <div className="mt-20 flex items-center justify-between px-10 lg:min-w-[30rem] xl:min-w-[50dvw]">
      <div className="w-full">
        <h2 className="mb-10 text-3xl font-bold">
          {view ? "View" : selectedProject ? "Update" : "Create"} Committee
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="department_name">Name</label>
            <input
              type="text"
              name="department_name"
              id="department_name"
              required
              className="rounded-lg border border-gray-200 p-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={view}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="department_description">Description</label>
            <textarea
              name="department_description"
              id="department_description"
              className="rounded-lg border border-gray-200 p-2"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={view}
            />
          </div>
          {/* list users to be selected inside this department */}
          <div className="mt-4">
            <h3 className="">Select Users</h3>
            {selectedUsers.length > 0 && (
              <div className="flex max-h-[40rem] flex-col gap-2 overflow-y-auto">
                {selectedUsers.map((user, i) => (
                  <div
                    key={i}
                    className="flex w-full items-center justify-between rounded-lg bg-[#F0F5FF] p-2"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        src={
                          user.profile?.file ||
                          "/src/assets/img/avatars/dummy.png"
                        }
                        alt={user.profile?.first_name}
                        className="h-8 w-8 rounded-full"
                      />
                      <p>{`${user.profile?.first_name} ${user.profile?.last_name}`}</p>
                    </div>
                    {!view && (
                      <button
                        type="button"
                        className="mr-2"
                        onClick={() => {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (selected) => selected !== user
                            )
                          );
                        }}
                      >
                        <BsTrash size={16} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="mt-4 flex justify-between">
              {!view && (
                <HigherOffDropdown
                  id="usersList"
                  title={"Select A user"}
                  extra={"w-full"}
                  higherOfficals={users ? filteredUsers(users) : []}
                  onClick={(item) => {
                    if (!selectedUsers.includes(item)) {
                      setSelectedUsers([...selectedUsers, item]);
                    }
                  }}
                  value={null}
                />
              )}
            </div>
          </div>

          {!view && (
            <button
              type="submit"
              className="rounded-lg bg-[#485FE5] px-5 py-2 text-white"
            >
              {selectedProject
                ? loading
                  ? "Updating..."
                  : "Update"
                : loading
                ? "Creating..."
                : "Create"}
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
export default DepartmentSidebar;
