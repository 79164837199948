import StatusShow from "components/StatusShow";
import { useEffect, useState } from "react";

function DeliverableRowForm({
  showMilestoneForm,
  setShowMilestoneForm,
  handleSave,
  data,
  edit = false,
  setEdit,
}) {
  const [status, setStatus] = useState("not_started");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [target, setTarget] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState("");

  const submit = (e) => {
    e.preventDefault();
    handleSave({
      deliverableName: name,
      deliverableDescription: description,
      deliverableBudget: endDate,
      startDate: startDate,
      endDate: startDate,
      measurementUnit,
      target,
      // statusMemo: statusMemo,
      status,
    });
  };

  useEffect(() => {
    if (edit) {
      setName(data?.deliverable_name);
      setDescription(data?.deliverable_description || "");
      setStartDate(data?.start_date?.substring(0, 10));
      setStartDate(data?.end_date?.substring(0, 10));
      setStatus(data?.status);
      setTarget(data?.target);
      setMeasurementUnit(data?.measurement_unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <form
      className={`${showMilestoneForm ? "relative" : "hidden"} mt-2 pb-14`}
      onSubmit={submit}
    >
      <div className="flex items-start justify-between gap-10 rounded-lg bg-white py-2 px-4 text-gray-700">
        {/* left side */}
        <div className="flex-grow">
          <div className="mt-4 flex items-center justify-between">
            <div>
              <h1>
                Name <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Write a name</p>
            </div>
            <input
              id="milestoneName"
              type="text"
              placeholder="Deliverable Name"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>

          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>Description</h1>
              <p className="text-xs text-gray-500">Short Description</p>
            </div>
            <textarea
              id="deliverableDescription"
              placeholder="Deliverable Description"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>

          <div className="mt-4 flex items-center justify-between">
            <h1>Annual Target</h1>
            <input
              id="deliverableTarget"
              type="text"
              placeholder="Annual Target"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setTarget(e.target.value)}
              value={target}
            />
          </div>
        </div>
        {/* Right side */}
        <div className=" flex-grow">
          <div className="mt-4 flex items-center justify-between">
            <h1>Measurement Unit</h1>
            <input
              id="measurementUnit"
              type="text"
              placeholder="Measurement Unit"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setMeasurementUnit(e.target.value)}
              value={measurementUnit}
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>
                Start Date <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Type Date</p>
            </div>
            <input
              id="deliverableStartDate"
              type="date"
              placeholder="Start Date"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              required
            />
          </div>

          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>
                End Date <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Type Date</p>
            </div>
            <input
              id="deliverableEndDate"
              type="date"
              placeholder="End Date"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              required
            />
          </div>

          {/* <div className="mt-4 flex items-center justify-between">
            <h1>
              Status Memo <span className="text-red-700">*</span>
            </h1>
            <input
              id="deliverableStatusMemo"
              type="text"
              placeholder="Status Memo"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setStatusMemo(e.target.value)}
              value={statusMemo}
              required
            />
          </div> */}

          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>Status</h1>
              <p className="text-xs text-gray-500">Choose status</p>
            </div>
            <StatusShow status={status || "not_started"} />
          </div>
        </div>
      </div>

      <div className="absolute right-3 bottom-0 flex items-center gap-5">
        <div
          className="flex cursor-pointer items-center gap-1 rounded-md bg-gray-500 py-1.5 px-3 text-white"
          onClick={() => {
            setEdit(null);
            setShowMilestoneForm(false);
          }}
        >
          Cancel
        </div>

        <button
          className=" flex items-center gap-1 rounded-md bg-[#485FE5] py-1.5 px-6 text-white"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
export default DeliverableRowForm;
