import Card from "components/card";
import BarChart from "components/charts/BarChart";
const ProjectsYearCount = ({ yearCount, title }) => {
  return (
    <Card extra="px-3 py-3 h-full md:min-h-[225px] w-full bg-[#e6e6e659]/10 shadow-md">
      <div className="px-4 pt-1">
        <h1 className="text-xl text-[#787486] dark:text-white">
          {`All Registered ${title || "Tasks"} per Year`}
        </h1>
      </div>

      {yearCount && (
        <BarChart
          chartData={[
            {
              name: title || "Tasks",
              data: yearCount?.map((item) => item.count),
              color: "#6AD2Fa",
            },
          ]}
          chartOptions={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: yearCount?.map((item) => item.year),
              show: false,
              labels: {
                show: true,
                style: {
                  colors: "#A3AED0",
                  fontSize: "14px",
                  fontWeight: "500",
                },
              },
            },
            grid: {
              show: false,
            },
            fill: {
              type: "solid",
              colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
            },
            legend: {
              show: false,
            },
            colors: ["#5E37FF", "#6AD2FF", "#E1E9F8"],
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: "8px",
              },
            },
          }}
        />
      )}
    </Card>
  );
};

export default ProjectsYearCount;
