/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import { useNavigate, useParams } from "react-router-dom";
import DeliverablesList from "views/admin/ceo/components/DeliverablesList";
import ProjectMilestone from "views/admin/ceo/components/ProjectMilestone";
import ProjectStatus from "views/admin/ceo/components/ProjectStatus";
import { ministryRouteNameContext } from "layouts/ministry";

function ProjectDetails({ context }) {
  let navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute, currentRoute } = useContext(
    context || ministryRouteNameContext
  );
  const [showDeliverables, setShowDeliverables] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({});

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/main_app/projects/${params.id}/`,
      method: "GET",
    },
    { manual: true }
  );
  const [{ data: milestones, error: milestoneError }, fetchMilestones] =
    useAxios(
      {
        url: `/main_app/projects/${params.id}/nested/`,
        method: "GET",
      },
      { manual: true }
    );

  useEffect(() => {
    if (params.id) {
      fetchProject();
      fetchMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (project && currentRoute !== project?.project_name) {
      setCurrentRoute(`${project?.project_name}`);
    }
  }, [project]);

  return (
    <div className="mt-5 fill-blueSecondary font-poppins">
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex gap-8">
              <BackIcon
                onClick={() => {
                  if (showDeliverables) {
                    setShowDeliverables(false);
                    return;
                  }
                  navigate(-1, { replace: true });
                }}
                className="cursor-pointer"
              />
              <div className="mt-4 flex items-start gap-3">
                <div className="h-4 w-4 bg-orange-400" />
                <div>
                  <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                    Task Detail
                  </p>
                  <p className="mt-2 space-x-6 font-semibold tracking-widest text-gray-700 dark:text-white ">
                    Task ID:
                    <span className="font-normal tracking-widest text-gray-500">
                      #{project?.id}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="gap-2 3xl:flex">
              <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
                {/* Milestones */}
                {milestoneError && <Error error={milestoneError} />}

                {!showDeliverables &&
                  milestones?.milestone_set?.map((milestone) => (
                    <ProjectMilestone
                      key={milestone.id}
                      data={{
                        ...milestone,
                        project_status: project?.status,
                      }}
                      setSelectedActivity={setSelectedActivity}
                      setShowDeliverables={setShowDeliverables}
                    />
                  ))}
                {showDeliverables &&
                  selectedActivity?.deliverables?.map((deliverable) => (
                    <DeliverablesList
                      key={deliverable?.id}
                      data={deliverable}
                      setSelectedActivity={setSelectedActivity}
                      activityName={selectedActivity?.activity_name}
                      setShowDeliverables={setShowDeliverables}
                    />
                  ))}
              </div>
              <ProjectStatus project={{ ...project, milestones }} />
            </div>
          </>
        )
      ) : (
        <Error error={error} />
      )}
    </div>
  );
}

export default ProjectDetails;
