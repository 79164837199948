import useAxios from "axios-hooks";
import StatusDropdown from "components/dropdown/StatusDropdownDeliverable";
import AddIconFilled from "components/icons/AddIconFilled";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import StatusShow from "components/StatusShow";
import { useContext, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import SubDeliverableModal from "views/admin/expert/components/SubDeliverableModal";
import SubDeliverableRow from "views/admin/expert/components/SubDeliverableRow";
import { MilestoneContext } from "views/admin/expert/ProjectDetails";

function DeliverableTableRow({
  data,

  isCeo = false,
}) {
  const [viewForm, setViewForm] = useState(false);
  const [showChileDel, setShowChildDel] = useState(false);
  const { fetchMilestones } = useContext(MilestoneContext);
  const [{}, updateStatus] = useAxios(
    {
      url: `/main_app/deliverables/${data.id}/status/`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleStatusUpdate = (value) => {
    if (value.file) {
      const formData = new FormData();
      formData.append("status", value.status);
      formData.append("actual_target", value.target);
      formData.append("actual_start_date", value.startDate);
      formData.append("actual_end_date", value.endDate);
      formData.append("completion_file", value.file);
      formData.append("status_memo", value.status_memo);

      updateStatus({
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        fetchMilestones();
      });
    } else {
      updateStatus({
        data: { ...data, ...value },
        url: `/main_app/deliverables/${data.id}/`,
        method: "PUT",
      }).then(() => {
        fetchMilestones();
      });
    }
  };
  return (
    <table className="mt-3 w-full table-auto rounded bg-white dark:bg-navy-900">
      <thead>
        <tr className="bg-[#F7F9FCCC] text-[#687182]">
          <th></th>

          <th className="text-start font-normal">
            <p className="ml-4 max-w-[250px] truncate">Name</p>
          </th>
          <th className="font-normal">Description</th>
          {!isCeo && <th className="font-normal">Sub Deliverable</th>}
          <th>
            <div className="flex items-center justify-center gap-2 font-normal uppercase">
              <p>status</p>
            </div>
          </th>
          <th className="ml-4 flex items-center justify-center gap-2 font-normal uppercase">
            <p>Deliverable timeline</p>
          </th>
          <th className="font-normal">Measurement Unit</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="font-light">
        <tr
          className={`table-row overflow-x-auto ${
            showChileDel ? "bg-[#EDEDFC] dark:bg-navy-900 " : ""
          }`}
        >
          <td className="w-[40px]">
            <div className="ml-4 w-fit ">
              {showChileDel ? (
                <ChevronDownOutline
                  onClick={() => setShowChildDel(!showChileDel)}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              ) : (
                <ChevronRightOutline
                  onClick={() => setShowChildDel(!showChileDel)}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              )}
            </div>
          </td>
          <td className="w-[30%]">
            <div
              className={`text-start trun${
                showChileDel ? "dark:text-gray-800" : "dark:text-white"
              }`}
            >
              <p className="ml-4 dark:text-white">{data?.deliverable_name}</p>
            </div>
          </td>
          <td>
            <div className="flex justify-center">
              <p className="w-[110px] truncate text-center dark:text-white">
                {data?.deliverable_description}
              </p>
            </div>
          </td>
          {!isCeo && (
            <td>
              {viewForm && (
                <SubDeliverableModal
                  deliverable={data}
                  key={data?.id}
                  setOpen={setViewForm}
                />
              )}
              {data?.status !== "completed" && (
                <div
                  className="flex cursor-pointer justify-center"
                  onClick={() => {
                    setViewForm(true);
                  }}
                >
                  <AddIconFilled className="h-8 w-8 " />
                </div>
              )}
            </td>
          )}
          <td className="flex justify-center">
            {!isCeo && data.status !== "completed" ? (
              <StatusDropdown
                pos={"-left-28"}
                button={
                  <StatusShow status={data?.status} key={data?.id}>
                    <FaChevronDown size={10} className="cursor-pointer" />
                  </StatusShow>
                }
                onSubmit={(value) => {
                  handleStatusUpdate(value);
                }}
              />
            ) : (
              <StatusShow status={data?.status} />
            )}
          </td>
          <td>
            <div className="flex w-full items-center justify-center gap-1">
              <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
                {data?.start_date?.substring(0, 10)}
              </p>
              <BsChevronRight size={10} />
              <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
                {data?.end_date?.substring(0, 10)}
              </p>
            </div>
          </td>
          <td>
            <p className={`text-center dark:text-white`}>
              {data?.measurement_unit}
            </p>
          </td>
        </tr>
        {showChileDel &&
          data?.child_deliverables?.map((subDel) => (
            <SubDeliverableRow subDel={subDel} key={subDel.id} />
          ))}
      </tbody>
    </table>
  );
}
export default DeliverableTableRow;
