import useAxios from "axios-hooks";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import StatusShow from "components/StatusShow";

function ActivityRowForm({
  activityName,
  setActivityName,
  activityStatus,
  setActivityStatus,
  activityStart,
  setActivityStart,
  activityEnd,
  setActivityEnd,
  activityBudget,
  setActivityBudget,
  activityDesc,
  setActivityDesc,
  statusMemo,
  setStatusMemo,
  selectedDepartment,
  setSelectedDepartment,
  output,
  setOutput,
}) {
  const [{ data: departments }] = useAxios({
    url: "/account/departments/",
    method: "GET",
    params: {
      no_pagination: true,
    },
  });

  return (
    <div className="items-start justify-between gap-10 rounded-lg bg-white px-4 pt-2 pb-4 text-start text-gray-700 dark:bg-navy-800 dark:text-white md:flex">
      {/* left side */}
      <div className="flex-grow">
        <div className="mt-4 flex items-center justify-between">
          <div>
            <h1>
              Name <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Write a name</p>
          </div>
          <input
            id="activityName"
            type="text"
            placeholder="Activity Name"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityName(e.target.value)}
            value={activityName}
            required
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>Description</h1>
            <p className="text-xs text-gray-500">Short Description</p>
          </div>
          <textarea
            id="activityDescription"
            placeholder="Activity Description"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityDesc(e.target.value)}
            value={activityDesc}
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1 className="">Budget</h1>
            <p className="text-xs text-gray-500">Write a budget</p>
          </div>
          <input
            id="activityBudget"
            type="number"
            pattern="[0-9]*"
            placeholder="Activity Budget"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityBudget(e.target.value)}
            value={activityBudget}
          />
        </div>
        <div className="mt-4 flex justify-between">
          <p className="mt-2 w-[53%] lg:flex-grow-0">Committee</p>
          <MultiSelectDropdown
            key={departments}
            formFieldName={"Committee"}
            options={departments || []}
            name="department_name"
            selectedValues={selectedDepartment ? selectedDepartment : []}
            onChange={(selected) => {
              setSelectedDepartment(selected.map((item) => item.id));
            }}
            prompt="Select one or more committees"
          />
        </div>
      </div>
      {/* Right side */}
      <div className="flex-grow lg:min-w-[40%]">
        {/* <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>Deliverable/Output</h1>
            <p className="text-xs text-gray-500">Short description</p>
          </div>
          <textarea
            id="activityOutput"
            placeholder="Deliverable/Output"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
            onChange={(e) => setOutput(e.target.value)}
            value={output}
          />
        </div> */}
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              Start Date <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Type Date</p>
          </div>
          <input
            id="activityStartDate"
            type="date"
            placeholder="Start Date"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityStart(e.target.value)}
            value={activityStart}
            required
          />
        </div>
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              End Date <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Type Date</p>
          </div>
          <input
            id="activityEndDate"
            type="date"
            placeholder="Start Date"
            className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setActivityEnd(e.target.value)}
            value={activityEnd}
            required
          />
        </div>
        {/* <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>Status Memo</h1>
            <p className="text-xs text-gray-500">Write a status memo</p>
          </div>
          <input
            id="activityStatusMemo"
            type="text"
            placeholder="Activity Status Memo"
            className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2 dark:bg-navy-700"
            onChange={(e) => setStatusMemo(e.target.value)}
            value={statusMemo}
          />
        </div> */}
        <div className="mt-4 flex flex-grow items-center justify-between">
          <div>
            <h1>
              Status <span className="text-red-700">*</span>
            </h1>
            <p className="text-xs text-gray-500">Choose status</p>
          </div>
          <StatusShow status={activityStatus} />
        </div>
      </div>
    </div>
  );
}

export default ActivityRowForm;
