/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import SidebarModal from "components/popover/SidebarModal";
import { TLRouteNameContext } from "layouts/teamLeader";
import toast from "react-hot-toast";
import {
  IoCheckmark,
  IoChevronBack,
  IoChevronForward,
  IoDocument,
  IoPersonAdd,
} from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import DeliverablesList from "views/admin/ceo/components/DeliverablesList";
import ProjectMilestone from "views/admin/ceo/components/ProjectMilestone";
import ProjectReport from "views/admin/ceo/ProjectReport";
import ProjectStatus from "views/admin/expert/components/ProjectStatus";
import ExpertsDropdown from "views/admin/team-leader/components/ExpertsDropdown";

function ProjectDetails() {
  let navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute, currentRoute } = useContext(TLRouteNameContext);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDeliverables, setShowDeliverables] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({});

  const [selectedExpert, setSelectedExpert] = useState();

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/main_app/projects/${params.id}/`,
      method: "GET",
    },
    { manual: true }
  );

  const [{ data: milestones, error: milestoneError }, fetchMilestones] =
    useAxios(
      {
        url: `/main_app/projects/${params.id}/nested/`,
        method: "GET",
      },
      { manual: true, useCache: false }
    );

  const [{ data: experts, loading: expertLoad, error: expertsError }] =
    useAxios(
      {
        url: `/account/expert-users/`,
        method: "GET",
      },
      { useCache: false }
    );

  const [{ loading: putLoading, error: putError }, assignExpert] = useAxios(
    {
      url: `/main_app/projects/${params.id}/assign/`,
      method: "PUT",
    },
    { manual: true }
  );

  // const [{ loading: planLoading, error: planError }, executePlan] = useAxios(
  //   {
  //     url: `/main_app/projects/${project?.id}/execution-plan-status/`,
  //     method: "PUT",
  //   },
  //   { manual: true }
  // );

  const handleAssignExpert = async () => {
    try {
      await assignExpert({
        data: {
          assigned_to: selectedExpert?.profile?.user,
        },
      });
      toast.success("Expert assigned successfully", {
        icon: <IoCheckmark />,
        style: { color: "green" },
      });
      fetchProject();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleExecutePlan = async (status) => {
  //   try {
  //     await executePlan({
  //       data: {
  //         execution_plan_status: status,
  //         feedback: document.getElementById("executionPlanComment").value,
  //       },
  //     });

  //     toast.success(`Execution plan ${status} successfully`, {
  //       icon: <IoCheckmark />,
  //       style: { color: "green" },
  //     });

  //     fetchProject();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (params.id) {
      fetchProject();
      fetchMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (project && currentRoute !== `${project?.project_name} Detail`) {
      setCurrentRoute(`${project?.project_name} Detail`);
    }
    return () => {};
    // eslint-disable-next-line
  }, [project, currentRoute]);

  return (
    <div className="mt-5 fill-blueSecondary font-poppins">
      <ProjectReport
        project={{ ...project, milestonesData: milestones }}
        showModal={isOpen}
        setShowModal={setIsOpen}
      />
      <button
        className="fixed top-[50%] -right-0 rounded-full bg-[#485FE5] p-2 dark:bg-navy-700"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <IoChevronForward
          className={`h-7 w-7 rotate-180 text-white md:h-10 md:w-10`}
        />
      </button>
      <SidebarModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        content={<ProjectStatus project={project} setIsOpen={setIsOpen} />}
        closeBtn={
          <button
            className={`fixed top-8 left-[85%] z-50 rounded-full p-3 dark:bg-navy-700 md:left-[96%] md:top-6 3xl:top-[48%] 3xl:left-[66%] 3xl:bg-[#485FE5]`}
            onClick={() => {
              setShowModal(false);
            }}
          >
            <IoChevronBack
              className={`h-7 w-7 rotate-180 text-gray-800 3xl:h-10 3xl:w-10 3xl:text-white`}
            />
          </button>
        }
        bg={"bg-[#F5FAFF]"}
      />
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-wrap justify-between">
              <div>
                <BackIcon
                  onClick={() => {
                    if (showDeliverables) {
                      setShowDeliverables(false);
                      return;
                    }
                    navigate(-1, { replace: true });
                  }}
                  className="cursor-pointer"
                />
                <div className="ml-12 flex items-start gap-3">
                  <div className="h-4 w-4 bg-orange-400" />
                  <div>
                    <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                      Task Detail
                    </p>
                    {project?.execution_plan_status !== "pending" && (
                      <div
                        className="mt-2 flex w-fit items-center gap-2 rounded-md px-3 py-0.5"
                        style={{
                          backgroundColor:
                            project?.execution_plan_status === "approved"
                              ? "#E1FCEF"
                              : "#FFEDEF",
                        }}
                      >
                        <div
                          className={`h-2 w-2 ${
                            project?.execution_plan_status === "approved"
                              ? "bg-green-500"
                              : "bg-red-800"
                          }`}
                        />
                        <p
                          className={`text-sm font-[400] capitalize ${
                            project?.execution_plan_status === "approved"
                              ? "text-green-800"
                              : "text-red-800"
                          }`}
                        >
                          {project?.execution_plan_status}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              {project?.execution_plan_status === "pending" && (
                <div className="mt-5 flex items-center gap-3">
                  <Dropdown
                    button={
                      <button className="flex items-center gap-2 rounded-lg bg-[#485FE5] px-10 py-3 text-sm text-white">
                        <IoPersonAdd size={25} />
                        Assign Expert / Coordinator
                      </button>
                    }
                    classNames={
                      "top-16 -left-16 min-w-[250px] overflow-y-scroll max-h-[400px]"
                    }
                    animation={
                      "origin-top transition-all duration-300 ease-in-out"
                    }
                    id="expertsDropdown"
                    children={
                      <div className="min-w-[250px] bg-white">
                        {expertsError ? (
                          <Error error={error} />
                        ) : expertLoad ? (
                          <Loading />
                        ) : (
                          <ExpertsDropdown
                            assignedTo={project?.assigned_to}
                            experts={experts}
                            selectedExpert={selectedExpert}
                            setSelectedExpert={setSelectedExpert}
                            loading={putLoading}
                            error={putError}
                            assignExpert={handleAssignExpert}
                          />
                        )}
                      </div>
                    }
                  />
                </div>
              )}
              {/*  */}
            </div>
            <div className="gap-2 3xl:flex">
              <div className="mt-5 w-full flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
                {project?.project_description && (
                  <>
                    <h1 className="ml-2 font-semibold text-navy-500">
                      Description
                    </h1>
                    <p className="mt-2 ml-2 w-full rounded-lg bg-white p-3 text-sm text-[#787486] dark:bg-navy-900 dark:text-white ">
                      {project?.project_description}
                    </p>
                  </>
                )}

                {/* Milestones */}
                {milestoneError && <Error error={milestoneError} />}

                {!showDeliverables &&
                  milestones?.milestone_set?.map((milestone) => (
                    <ProjectMilestone
                      key={milestone.id}
                      data={{
                        ...milestone,
                        project_status: project?.status,
                      }}
                      setSelectedActivity={setSelectedActivity}
                      setShowDeliverables={setShowDeliverables}
                    />
                  ))}
                {showDeliverables &&
                  selectedActivity?.deliverables?.map((deliverable) => (
                    <DeliverablesList
                      key={deliverable?.id}
                      data={deliverable}
                      setSelectedActivity={setSelectedActivity}
                      activityName={selectedActivity?.activity_name}
                      setShowDeliverables={setShowDeliverables}
                    />
                  ))}
              </div>

              <div className="w-full 3xl:w-1/4">
                <div className="mt-5 h-fit max-w-full rounded-lg bg-[#F2F9FFCC] py-5 px-6 font-poppins dark:bg-navy-700">
                  <p className="text-lg font-semibold text-gray-700 dark:text-white ">
                    Files
                  </p>
                  {project?.files?.map((file) => (
                    <div
                      key={file.id}
                      className="mt-8 flex flex-wrap items-start gap-5 "
                    >
                      <IoDocument color="#2F80ED" size={45} />
                      <p className="text-sm dark:text-white">
                        {file.file.split("/").pop().split(".")[0]}
                      </p>
                      <a
                        href={file.file}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer text-sm text-blueSecondary underline"
                      >
                        open
                      </a>
                    </div>
                  ))}
                </div>
                {/*  */}
              </div>
            </div>
          </>
        )
      ) : (
        <Error error={error} />
      )}
    </div>
  );
}

export default ProjectDetails;
