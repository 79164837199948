import { useState } from "react";
import {
  IoAddCircleOutline,
  IoChevronDownCircleOutline,
} from "react-icons/io5";
import Loading from "components/loading";
import MilesstoneViewTableRow from "./MilesstoneViewTableRow";
import AssMilestoneRowForm from "./AssMilestoneRowForm";

function AssignmentMilestoneForm({
  data,
  removeExBody,
  handleMilestoneSave,
  loading,
  setShowActivities,
  selectedMilestone,
  setSelectedMilestone,
  handleMileDelete,
  fetchMilestones,
}) {
  const [showMilestone, setShowMilestone] = useState(false);
  const [showMileForm, setShowMileForm] = useState(false);
  const [editMilestone, setEditMilestone] = useState(false);

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          {data?.executing_body_name || "Executing Body Name"}
        </h1>
      </div>
      {/*  */}
      {loading ? (
        <Loading />
      ) : (
        <div className="m-3">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              Milestones
            </span>
            <div className={`flex items-center gap-2`}>
              <IoChevronDownCircleOutline
                color="#687182"
                className={`h-5 w-5 transform cursor-pointer ${
                  showMilestone ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setShowMilestone(!showMilestone);
                  setShowMileForm(false);
                }}
              />
            </div>
          </div>
          {showMilestone && (
            <MilesstoneViewTableRow
              setShowActivities={setShowActivities}
              setSelectedMilestone={setSelectedMilestone}
              fetchMilestones={fetchMilestones}
              setEditMilestone={() => {
                setEditMilestone(true);
                setShowMileForm(true);
                setShowMilestone(false);
              }}
              handleMileDelete={handleMileDelete}
              data={data}
            />
          )}

          {showMileForm && (
            <AssMilestoneRowForm
              edit={editMilestone}
              setEdit={setEditMilestone}
              data={selectedMilestone}
              setShowMilestoneForm={setShowMileForm}
              showMilestoneForm={showMileForm}
              handleSave={async (formData, mileId) => {
                handleMilestoneSave(
                  { ...formData, executing_body_id: data?.id },
                  editMilestone ? mileId : null
                ).then((res) => {
                  if (res === true) {
                    setShowMileForm(false);
                    setShowMilestone(true);
                  }
                });
              }}
            />
          )}
        </div>
      )}
      {/*  */}
    </div>
  );
}

export default AssignmentMilestoneForm;
