import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";

function Paginate({ count = 0, next, previous, page, setPage }) {
  const totalPages = Math.ceil(count / 5);
  const pageBreak = 6;
  if (totalPages < pageBreak) {
    return (
      <div className="mt-5 flex w-full items-center justify-center gap-3">
        <BsChevronDoubleLeft
          size={15}
          className={`mr-2 cursor-pointer
        ${page === 1 ? "cursor-default text-gray-300 " : "text-gray-900"}
        `}
          onClick={previous}
        />
        {Array.from({ length: totalPages }).map((_, index) => (
          <div
            key={index}
            className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${parseInt(page) === index + 1 ? "bg-blue-500 text-white" : ""}
            `}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </div>
        ))}
        <BsChevronDoubleRight
          size={15}
          className={`ml-2 cursor-pointer
        ${
          page === totalPages
            ? "cursor-default text-gray-300 "
            : "text-gray-900"
        }
          `}
          onClick={next}
        />
      </div>
    );
  }
  if (page < pageBreak) {
    return (
      <div className="mt-5 flex w-full items-center justify-center gap-3">
        <BsChevronDoubleLeft
          size={15}
          className={`mr-2 cursor-pointer
        ${page === 1 ? "cursor-default text-gray-300 " : "text-gray-900"}
        `}
        />
        {Array.from({ length: pageBreak }).map((_, index) => (
          <div
            key={index}
            className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${parseInt(page) === index + 1 ? "bg-blue-500 text-white" : ""}
            `}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </div>
        ))}
        <BsChevronDoubleRight
          size={15}
          className={`ml-2 cursor-pointer
        ${
          page === totalPages
            ? "cursor-default text-gray-300 "
            : "text-gray-900"
        }
          `}
          onClick={next}
        />
      </div>
    );
  }
  if (page >= pageBreak && page < totalPages - 2) {
    return (
      <div className="mt-5 flex w-full items-center justify-center gap-3">
        <BsChevronDoubleLeft
          size={15}
          className={`mr-2 cursor-pointer
        ${page === 1 ? "cursor-default text-gray-300 " : "text-gray-900"}
        `}
          onClick={previous}
        />
        {Array.from({ length: pageBreak }).map((_, index) => (
          <div
            key={index}
            className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${parseInt(page) === index + 1 ? "bg-blue-500 text-white" : ""}
            `}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </div>
        ))}
        <div className="w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm">
          ...
        </div>
        <div
          className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${parseInt(page) === totalPages ? "bg-blue-500 text-white" : ""}
            `}
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </div>
        <BsChevronDoubleRight
          size={15}
          className={`ml-2 cursor-pointer
        ${
          page === totalPages
            ? "cursor-default text-gray-300 "
            : "text-gray-900"
        }
          `}
          onClick={next}
        />
      </div>
    );
  }
  if (page >= totalPages - 2) {
    return (
      <div className="mt-5 flex w-full items-center justify-center gap-3">
        <BsChevronDoubleLeft
          size={15}
          className={`mr-2 cursor-pointer
        ${page === 1 ? "cursor-default text-gray-300 " : "text-gray-900"}
        `}
          onClick={previous}
        />
        <div
          className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${parseInt(page) === 1 ? "bg-blue-500 text-white" : ""}
            `}
          onClick={() => setPage(1)}
        >
          1
        </div>
        <div className="w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm">
          ...
        </div>
        {Array.from({ length: pageBreak }).map((_, index) => (
          <div
            key={index}
            className={`w-fit cursor-pointer rounded-full bg-gray-200 px-4 py-2 text-sm
          ${
            parseInt(page) === totalPages - pageBreak + index + 1
              ? "bg-blue-500 text-white"
              : ""
          }
            `}
            onClick={() => setPage(totalPages - pageBreak + index + 1)}
          >
            {totalPages - pageBreak + index + 1}
          </div>
        ))}
        <BsChevronDoubleRight
          size={15}
          className={`ml-2 cursor-pointer
        ${
          page === totalPages
            ? "cursor-default text-gray-300 "
            : "text-gray-900"
        }
          `}
          onClick={next}
        />
      </div>
    );
  }
}
export default Paginate;
