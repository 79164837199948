import Dropdown from "components/dropdown";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import ActivityViewModal from "components/popover/ActivityViewModal";
import StatusShow from "components/StatusShow";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";

function SubActivityRow({ subActivity, padding = "" }) {
  const [showMoreActivity, setShowMoreActivity] = useState(false);
  const [view, setView] = useState(false);
  const toggleShowMore = () => {
    setShowMoreActivity(!showMoreActivity);
  };
  return (
    <>
      <tr
        className={`table-row ${
          showMoreActivity
            ? "bg-[#EDEDFC] dark:bg-navy-900 "
            : "bg-gray-50 dark:bg-navy-900"
        }`}
      >
        <td colSpan={2} className="">
          <div
            className={`relative ml-10 flex w-[150px] max-w-[300px] ${padding}`}
          >
            <div className=" flex items-center justify-between gap-3 text-start">
              {showMoreActivity ? (
                <ChevronDownOutline
                  onClick={toggleShowMore}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              ) : (
                <ChevronRightOutline
                  onClick={toggleShowMore}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              )}
              {/* <p className="dark:text-white">{subActivity?.id}</p> */}
              <p className="dark:text-white">{subActivity?.activity_name}</p>
            </div>
          </div>
        </td>
        <td className="">
          <div className="flex justify-center">
            <p className="w-[110px] truncate text-center dark:text-white">
              {subActivity?.activity_description}
            </p>
          </div>
        </td>

        <td className="flex justify-center">
          <StatusShow status={subActivity?.status} />
        </td>

        <td>
          <div className="flex w-full items-center justify-center gap-1">
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
              {subActivity?.start_date}
            </p>
            <BsChevronRight size={10} />
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
              {subActivity?.end_date}
            </p>
          </div>
        </td>
        <td>
          <p>{subActivity?.activity_budget}</p>
        </td>
        <td>
          {view && (
            <ActivityViewModal
              id={"activity" + subActivity.id}
              data={subActivity}
            />
          )}
          <Dropdown
            button={<HiDotsVertical className="cursor-pointer" />}
            id={`dropdown${subActivity.id}`}
            classNames={"top-5 min-w-max -left-24"}
            children={
              <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setView(true);
                  }}
                >
                  View
                </p>
              </div>
            }
          />
        </td>
      </tr>
      {showMoreActivity &&
        subActivity?.sub_activities?.map((subActivity) => (
          <SubActivityRow
            key={subActivity.id}
            subActivity={subActivity}
            padding="pl-5"
          />
        ))}
    </>
  );
}
export default SubActivityRow;
