import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import StatusDropdownMilestone from "components/dropdown/StatusDropdownMilestone";
import StatusShow from "components/StatusShow";
import { FaChevronDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import DeleteModal from "views/admin/expert/components/DeleteModal";

function MilesstoneViewTableRow({
  data,
  setShowActivities,
  setSelectedMilestone,
  setEditMilestone,
  handleMileDelete,
  fetchMilestones,
}) {
  const [, updateStatus] = useAxios(
    {
      url: `/ministry_tasks/assignment-milestone/${data.id}/update-completion/`,
      method: "PUT",
    },
    { manual: true }
  );

  const [{ data: users, loading: usersLoad }] = useAxios({
    url: "/account/user_list",
    params: {
      no_pagination: true,
    },
  });

  const getAssignedUsers = (userIds) => {
    return users?.filter((user) => userIds.includes(user?.profile?.user));
  };

  const handleStatusUpdate = (value, mile) => {
    if (value.status === "completed") {
      const formData = new FormData();
      formData.append("real_start_date", value.startDate);
      formData.append("real_end_date", value.endDate);
      formData.append("completion_file", value.file || null);
      formData.append("status_memo", value.status_memo || null);

      updateStatus({
        data: formData,
        url: `/ministry_tasks/assignment-milestone/${mile.id}/update-completion/`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        fetchMilestones();
      });
    } else {
      updateStatus({
        data: { ...mile, ...value },
        url: `/ministry_tasks/assignment-milestones/${mile.id}/`,
        method: "PUT",
      }).then(() => {
        fetchMilestones();
      });
    }
  };
  return (
    <table className="mt-3 w-full table-auto rounded bg-white dark:bg-navy-900">
      <thead>
        <tr className="bg-[#F7F9FCCC] text-[#687182]">
          <th></th>

          <th className="text-start font-normal">
            <p className="ml-4 max-w-[250px] truncate">Name</p>
          </th>
          <th className="font-normal">Responsible Parties</th>
          <th>
            <div className="flex items-center justify-center gap-2 font-normal uppercase">
              <p>status</p>
            </div>
          </th>
          <th className="ml-4 flex items-center justify-center gap-2 font-normal uppercase">
            <p>End date</p>
          </th>
          <th className="font-normal">Activities</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="font-light">
        {data?.individual_assignment_milestones?.map((milestone) => {
          return (
            <tr
              className={`table-row overflow-x-auto border-b`}
              key={milestone.id}
            >
              <td />
              <td className="w-[30%]">
                <div
                  className={`text-start
                dark:text-white`}
                >
                  <p className="ml-4 dark:text-white">
                    {milestone?.assignment_milestone_name}
                  </p>
                </div>
              </td>
              <td>
                <div className="flex justify-center">
                  <div className="w-[200px] truncate text-center dark:text-white">
                    {usersLoad && <span className="text-xs">loading...</span>}{" "}
                    <select
                      className="w-full rounded-md border border-gray-300 p-1 dark:bg-navy-900 dark:text-white"
                      disabled={
                        getAssignedUsers(milestone.assigned_to)?.length < 1
                      }
                    >
                      {getAssignedUsers(milestone.assigned_to)?.map(
                        (user, i) => (
                          <option key={i} value={user?.id}>
                            {user?.profile?.first_name}{" "}
                            {user?.profile?.last_name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </td>

              <td className="flex justify-center">
                {milestone.status !== "completed" ? (
                  <StatusDropdownMilestone
                    pos={"-left-28"}
                    button={
                      <StatusShow
                        status={milestone?.status}
                        key={milestone?.id}
                      >
                        <FaChevronDown size={10} className="cursor-pointer" />
                      </StatusShow>
                    }
                    onSubmit={(value) => {
                      handleStatusUpdate(value, milestone);
                    }}
                  />
                ) : (
                  <StatusShow status={milestone?.status} />
                )}
              </td>
              <td>
                <div className="flex w-full items-center justify-center gap-1">
                  <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
                    {milestone?.end_date?.substring(0, 10)}
                  </p>
                </div>
              </td>
              <td>
                <div
                  className="flex cursor-pointer justify-center"
                  onClick={() => {
                    setSelectedMilestone(milestone);
                    setShowActivities(true);
                  }}
                >
                  <button className="w-fit rounded-lg bg-blue-500 px-3 py-0.5 text-sm text-white">
                    Open
                  </button>
                </div>
              </td>
              <td>
                <DeleteModal
                  id={`deleteMilestone${milestone.id}`}
                  handleConfirm={() => {
                    handleMileDelete(milestone.id);
                    window.document
                      .getElementById(`deleteMilestone${milestone.id}`)
                      .classList.replace("flex", "hidden");
                  }}
                  handleCancel={() => {
                    window.document
                      .getElementById(`deleteMilestone${milestone.id}`)
                      .classList.replace("flex", "hidden");
                  }}
                />
                <Dropdown
                  button={<HiDotsVertical className="cursor-pointer" />}
                  id={`dropdown${data.id}`}
                  classNames={"top-5 min-w-max -left-24"}
                  children={
                    <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
                      {data.status !== "completed" && (
                        <p
                          className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                          onClick={() => {
                            setSelectedMilestone(milestone);
                            setEditMilestone(true);
                          }}
                        >
                          Edit
                        </p>
                      )}
                      <p
                        className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                        onClick={() => {
                          window.document
                            .getElementById(`deleteMilestone${milestone.id}`)
                            .classList.replace("hidden", "flex");
                        }}
                      >
                        Delete
                      </p>
                    </div>
                  }
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default MilesstoneViewTableRow;
