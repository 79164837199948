/* eslint-disable react-hooks/exhaustive-deps */
import { FiX } from "react-icons/fi";
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";

function ExecutingBodyModal({
  data,
  update,
  setOpen,
  refetch,
  setSelectedExBody,
  selectedExBody,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [{ loading, error }, post] = useAxios(
    {
      url: "/ministry_tasks/executing-bodies/",
      method: update ? "PUT" : "POST",
    },
    { manual: true }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let success = false;
    const formData = {
      executing_body_name: name,
      description: description,
      individual_assignment_id: update
        ? data?.individual_assignment_id
        : data?.id,
    };
    try {
      await post({
        url: update
          ? `/ministry_tasks/executing-bodies/${data.id}/`
          : "/ministry_tasks/executing-bodies/",
        method: update ? "PUT" : "POST",
        data: formData,
      }).then(async () => {
        refetch();
        setOpen(false);
        toast(`Executing body ${update ? "updated" : "created"} successfully`, {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
      });
    } catch (error) {
      console.log(error);
      success = false;
    }

    return success;
  };

  useEffect(() => {
    if (update && data) {
      setName(data.executing_body_name || "");
      setDescription(data.description || "");
    } else {
      setName("");
      setDescription("");
    }
  }, [update, data]);

  return (
    <div
      id={`executing_body_modal_form${data.id}`}
      tabIndex="-1"
      className="fixed inset-0 z-40 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative w-[900px] rounded-lg bg-white dark:bg-navy-800">
        <button
          tabIndex="-1"
          className="absolute top-8 right-6"
          onClick={() => {
            setOpen(false);
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>
        <div className="flex flex-col px-6 pt-8 text-start lg:px-12">
          <h1 className="text-3xl font-semibold text-gray-900">
            {update ? "Update Executing Body" : "Create Executing Body"}
          </h1>
          <p className=" text-gray-700">
            {data?.executing_body_name || "Executing Body Name"}
          </p>
        </div>
        {error && <Error error={error} />}
        {loading && <Loading />}
        {!loading && (
          <div className="flex flex-col px-6 pb-6 text-start lg:px-12">
            <form
              id={`executing_body${data.id}`}
              onSubmit={handleSubmit}
              className="flex flex-col items-stretch justify-around rounded-lg bg-white py-2 text-start text-gray-700 lg:flex-row lg:gap-10"
            >
              {/* left side */}
              <div className="flex-grow">
                <div className="mt-4 flex items-center justify-between">
                  <div>
                    <h1>
                      Name <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Write a name</p>
                  </div>
                  <input
                    id="executingBodyName"
                    type="text"
                    placeholder="Name"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Description</h1>
                    <p className="text-xs text-gray-500">Short Description</p>
                  </div>
                  <textarea
                    id="executingBodyDescription"
                    placeholder="Description"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
              </div>
            </form>
            <button
              type="submit"
              form={`executing_body${data.id}`}
              className="mt-3 self-end rounded-lg bg-blue-600 px-10 py-3 text-white"
            >
              Save
            </button>
            {/*  */}
          </div>
        )}
      </div>
    </div>
  );
}
export default ExecutingBodyModal;
