/* eslint-disable react-hooks/exhaustive-deps */
import FailedX from "components/icons/FailedX";
import { useVerifyEmailMutation } from "features/auth/authApiSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function VerifyAcc() {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const uid = params["*"].split("/")[0];
  const token = params["*"].split("/")[1];
  const [verifyEmail] = useVerifyEmailMutation();

  const handleVerify = async () => {
    try {
      await verifyEmail({ uid, token }).unwrap();
      setSuccess(true);
    } catch (error) {
      console.log("error", error);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (uid && token) {
      // verify email
      handleVerify().then(() => setLoading(false));
    } else return () => {};
  }, [uid, token]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const VerifyFailed = () => {
    return (
      <div className="h-full w-full bg-[#F0F2FF] text-center font-poppins text-[#992823]">
        <div className="absolute top-[46%] left-0 min-h-[300px] w-[550px] rounded-md bg-white md:top-64 md:left-[10%] lg:left-[30%] xl:left-[35%]">
          <div className="absolute -top-20 left-52 w-fit rounded-full bg-[#F0F2FF] p-4">
            <div className="w-fit rounded-full bg-white p-10">
              <FailedX />
            </div>
          </div>
          <div className="mx-auto mt-24 max-w-md">
            <h1 className="my-5 text-2xl font-bold">
              The email is link is expired!
            </h1>

            <p className="my-6 text-[#D86863]">
              The link provided in the email has expired. Kindly request a new
              email verification by using your email address.
            </p>

            <button
              className="mb-10 rounded-md bg-[#485FE5] py-3 px-10 font-semibold text-white"
              onClick={() => {
                navigate("/auth/sign-up");
              }}
            >
              Send Again
            </button>
          </div>
        </div>
      </div>
    );
  };

  const VerifySuccess = () => (
    <div className="h-full w-full bg-[#F0F2FF] text-center font-poppins text-[#485FE5]">
      <div className="absolute top-[46%] min-h-[300px] w-[550px] rounded-md bg-white md:left-[20%] md:top-64 lg:left-[35%]">
        <div className="absolute -top-20 left-52 w-fit rounded-full bg-[#F0F2FF] p-4">
          <div className="w-fit rounded-full bg-white p-10">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0826 26.1682L0 13.0826L4.35983 8.72275L13.0826 17.4424L30.5219 0L34.8848 4.36292L13.0826 26.1621V26.1682Z"
                fill="#485FE5"
              />
            </svg>
          </div>
        </div>
        <div className="mx-auto mt-24 max-w-md">
          <h1 className="my-5 text-2xl font-bold">
            The email is successfully verified!
          </h1>
          <p className="my-5 mx-10 text-green-600">
            Please proceed to the login page to access your account!.
          </p>

          <button
            className="mb-10 mt-5 rounded-md bg-[#485FE5] py-3 px-10 font-semibold text-white"
            onClick={() => {
              navigate("/auth/sign-in");
            }}
          >
            Back to Login page
          </button>
        </div>
      </div>
    </div>
  );

  return loading ? (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-brand-500"></div>
    </div>
  ) : success ? (
    <VerifySuccess />
  ) : (
    <VerifyFailed />
  );
}

export default VerifyAcc;
