import Card from "components/card";
import AreaChart from "components/charts/AreaChart";
const ProjectsByMonth = ({
  projectsMonth,
  year,
  setYear,
  yearRange,
  title,
}) => {
  return (
    <Card extra="px-3 py-3 h-full w-full bg-[#e6e6e659]/10 shadow-md">
      <div className="ml-1 mb-2 flex justify-between pt-2">
        <p className="text-xl font-[500] leading-4 text-[#787486]">
          {`Registered ${title || "Tasks"} per Month`}
        </p>
        <select
          id="year-select"
          value={year}
          onChange={(e) => setYear(parseInt(e.target.value, 10))}
          className="px-2 text-lg focus:outline-none focus:ring-0"
        >
          {yearRange.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {projectsMonth && (
        <AreaChart
          series={[
            {
              name: title || "Tasks",
              data: projectsMonth?.map((item) => item.count),
            },
          ]}
          options={{
            chart: {
              type: "area",
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            grid: {
              show: false,
            },

            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            },
            colors: ["#00D9A5"],
            xaxis: {
              categories: projectsMonth?.map((item) =>
                item.month.substring(0, 3)
              ),
            },
          }}
        />
      )}
    </Card>
  );
};

export default ProjectsByMonth;
