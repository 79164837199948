import { useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import {
  IoAddCircleOutline,
  IoChevronDownCircleOutline,
} from "react-icons/io5";
import Loading from "components/loading";
import { MdEdit } from "react-icons/md";
import DeliverableRowForm from "views/admin/expert/components/DeliverableRowForm";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import DeliverableTableRow from "views/admin/expert/components/DeliverableTableRow";

function DeliverableForm({
  data,
  projectName,
  removeMilestone,
  handleMilestoneSave: handleDeliverableSave,
  loading,
  activityId,
}) {
  const [showDeliverable, setShowDeliverable] = useState(false);
  const [showDeliverableForm, setShowDeliverableForm] = useState(false);
  const [editDeliverable, setEditDeliverable] = useState(false);
  const [showChileDel, setShowChildDel] = useState(false);
  // activities

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <DeleteModal
        key={data?.id}
        id={`DeleteModal${data?.id}`}
        handleCancel={() => {
          window.document
            .getElementById(`DeleteModal${data?.id}`)
            .classList.replace("flex", "hidden");
        }}
        handleConfirm={() => {
          removeMilestone(data?.id ? data?.id : null);
          window.document
            .getElementById(`DeleteModal${data?.id}`)
            .classList.replace("flex", "hidden");
        }}
      />
      <div className="flex justify-between">
        <h1 className="ml-3 max-w-[500px] truncate pt-3 font-semibold text-navy-500">
          {projectName}
        </h1>

        <BsTrashFill
          className="mt-3 mr-3 h-4 w-4 cursor-pointer text-red-500"
          onClick={() => {
            window.document
              .getElementById(`DeleteModal${data?.id}`)
              .classList.replace("hidden", "flex");
          }}
        />
      </div>
      {/*  */}
      {loading ? (
        <Loading />
      ) : (
        <div className="m-3">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              {data?.deliverable_name || "Deliverable Name"}
            </span>
            <div className={`flex items-center gap-2`}>
              {!data?.id ? (
                <IoAddCircleOutline
                  className="h-5 w-5 cursor-pointer text-brand-600"
                  onClick={() => {
                    setShowDeliverable(false);
                    setShowDeliverableForm(!showDeliverableForm);
                  }}
                />
              ) : (
                <>
                  {data.status !== "completed" && (
                    <MdEdit
                      color="#687182"
                      className="h-4 w-4 cursor-pointer "
                      onClick={() => {
                        setEditDeliverable(true);
                        setShowDeliverable(false);
                        setShowDeliverableForm(true);
                      }}
                    />
                  )}
                </>
              )}
              {data?.id && (
                <IoChevronDownCircleOutline
                  color="#687182"
                  className={`h-5 w-5 transform cursor-pointer ${
                    showDeliverable ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => {
                    setShowDeliverable(!showDeliverable);
                    setShowDeliverableForm(false);
                  }}
                />
              )}
            </div>
          </div>
          {showDeliverable && (
            <DeliverableTableRow
              data={data}
              showChileDel={showChileDel}
              setShowChildDel={setShowChildDel}
            />
          )}

          {showDeliverableForm && (
            <DeliverableRowForm
              edit={editDeliverable}
              setEdit={setEditDeliverable}
              data={data}
              setShowMilestoneForm={setShowDeliverableForm}
              showMilestoneForm={showDeliverableForm}
              handleSave={async (formData) => {
                handleDeliverableSave(
                  formData,
                  activityId,
                  editDeliverable ? data?.id : null
                ).then((res) => {
                  if (res === true) {
                    removeMilestone();
                    setShowDeliverableForm(false);
                    setShowDeliverable(!showDeliverable);
                  }
                });
              }}
            />
          )}
        </div>
      )}
      {/*  */}
    </div>
  );
}

export default DeliverableForm;
