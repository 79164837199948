import { useState, useEffect, useRef } from "react";

export default function MultiSelectDropdown({
  formFieldName,
  name,
  options,
  onChange,
  selectedValues = [],
  prompt = "Select one or more options",
}) {
  const [isJsEnabled, setIsJsEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const optionsListRef = useRef(null);

  useEffect(() => {
    setIsJsEnabled(true);
  }, []);

  useEffect(() => {
    if (selectedOptions.length < 1 && selectedValues.length > 0) {
      if (name === "assigned") {
        setSelectedOptions(
          options.filter((option) =>
            selectedValues.includes(option?.profile?.user)
          )
        );
      } else {
        setSelectedOptions(
          options.filter((option) => selectedValues.includes(option.id))
        );
      }
      const optionsInputs = optionsListRef.current.querySelectorAll("input");
      optionsInputs.forEach((input) => {
        if (selectedValues.includes(parseInt(input.value))) {
          input.checked = true;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const handleChange = (e, option) => {
    const isChecked = e.target.checked;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isSelectAllEnabled = selectedOptions.length < options.length;

  const handleSelectAllClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = true;
    });

    setSelectedOptions([...options]);
    onChange([...options]);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = false;
    });

    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <label className="relative flex-grow cursor-pointer rounded-lg bg-[#FAFAFA] p-4 lg:w-[64%] lg:flex-grow-0">
      <input type="checkbox" className="peer hidden" />

      <div className="inline-flex min-w-full flex-grow rounded after:ml-1 after:inline-flex after:items-center after:justify-between after:text-xs after:transition-transform after:content-['▼'] peer-checked:after:-rotate-180">
        <p className="flex flex-grow flex-wrap gap-2">
          {selectedOptions?.length < 1 && prompt}
          {isJsEnabled &&
            selectedOptions.length > 0 &&
            selectedOptions.map((item, i) => (
              <span
                key={i}
                className="w-fit rounded-full bg-gray-200 px-2 py-0.5 text-xs"
              >
                {item?.profile
                  ? `${item.profile.first_name} ${item.profile.last_name}`
                  : item[name].substring(0, 12) + "..."}
              </span>
            ))}
        </p>
      </div>

      <div className="pointer-events-none absolute right-0.5 z-50 max-h-60 w-full overflow-y-scroll border bg-white pb-3 opacity-0 transition-opacity peer-checked:pointer-events-auto peer-checked:opacity-100">
        {isJsEnabled && (
          <ul>
            <li>
              <button
                onClick={handleSelectAllClick}
                disabled={!isSelectAllEnabled}
                className="w-full px-2 py-1 text-left text-blue-600 disabled:opacity-50"
              >
                {"Select All"}
              </button>
            </li>
            <li>
              <button
                onClick={handleClearSelectionClick}
                disabled={!isClearSelectionEnabled}
                className="w-full px-2 py-1 text-left text-blue-600 disabled:opacity-50"
              >
                {"Clear selection"}
              </button>
            </li>
          </ul>
        )}
        <ul ref={optionsListRef}>
          {options?.map((option, i) => {
            return (
              <li key={i}>
                <label
                  className={`flex cursor-pointer whitespace-nowrap px-2 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200`}
                >
                  <input
                    type="checkbox"
                    name={formFieldName}
                    value={option?.profile ? option.profile.user : option.id}
                    className="cursor-pointer"
                    onChange={(e) => handleChange(e, option)}
                  />
                  <span className="ml-1">
                    {option?.profile
                      ? `${option.profile.first_name} ${option.profile.last_name}`
                      : option[name]}
                  </span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </label>
  );
}
