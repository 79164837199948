/* eslint-disable no-empty-pattern */
import Dropdown from "components/dropdown";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import ActivityViewModal from "components/popover/ActivityViewModal";
import StatusShow from "components/StatusShow";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";
import SubActivityRow from "views/admin/expert/components/SubActivityRow";

function SubActivityViewTableRow({ data, setSelectedActivity }) {
  const [showMoreActivity, setShowMoreActivity] = useState(false);
  const [view, setView] = useState(false);
  const toggleShowMore = () => {
    setShowMoreActivity(!showMoreActivity);
  };

  return (
    <tbody>
      <tr
        className={`table-row overflow-x-auto ${
          showMoreActivity ? "bg-[#EDEDFC] dark:bg-navy-900 " : ""
        }`}
      >
        <td className="w-[40px]">
          <div className="ml-4 w-fit ">
            {showMoreActivity ? (
              <ChevronDownOutline
                onClick={toggleShowMore}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            ) : (
              <ChevronRightOutline
                onClick={toggleShowMore}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            )}
          </div>
        </td>

        <td className="w-[30%]">
          <div
            className={`text-start trun${
              showMoreActivity ? "dark:text-gray-800" : "dark:text-white"
            }`}
          >
            <p className="ml-4 dark:text-white">{data?.sub_activity_name}</p>
          </div>
        </td>

        <td>
          <div className="flex justify-center">
            <p className="w-[110px] truncate text-center dark:text-white">
              {data?.sub_activity_description}
            </p>
          </div>
        </td>

        <td className="flex justify-center">
          <StatusShow status={data?.status} />
        </td>

        <td>
          <div className="flex w-full items-center justify-center gap-1">
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {data?.sub_activity_start_date.substring(0, 10)}
            </p>
            <BsChevronRight size={10} />
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {data?.sub_activity_end_date.substring(0, 10)}
            </p>
          </div>
        </td>
        <td>
          <p className={`dark:text-white `}>
            {Number(data?.sub_activity_budget).toLocaleString()}
          </p>
        </td>
        <td>
          {view && (
            <ActivityViewModal
              id={"activity" + data.id}
              data={data}
              setOpen={setView}
            />
          )}

          <Dropdown
            button={<HiDotsVertical className="cursor-pointer" />}
            id={`dropdown${data.id}`}
            classNames={"top-5 min-w-max -left-24"}
            children={
              <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setView(true);
                  }}
                >
                  View
                </p>
                {data.status !== "completed" && (
                  <p
                    className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                    onClick={() => setSelectedActivity(data)}
                  >
                    Edit
                  </p>
                )}
              </div>
            }
          />
        </td>
      </tr>
      {showMoreActivity &&
        data?.sub_activities?.map((subActivity) => (
          <SubActivityRow key={subActivity.id} subActivity={subActivity} />
        ))}
    </tbody>
  );
}

export default SubActivityViewTableRow;
