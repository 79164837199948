/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import BackIcon from "components/icons/BackIcon";
import Loading from "components/loading";
import { useNavigate, useParams } from "react-router-dom";
import AssignmentStatus from "views/admin/ministry/components/AssignmentStatus";
import MilestoneForm from "./components/MilestoneForm";
import { AssIndvRouteNameContext } from "layouts/assinment-individuals";
import SidebarModal from "components/popover/SidebarModal";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

function AssignmentDetails({ context }) {
  let navigate = useNavigate();
  const params = useParams();
  const { setCurrentRoute } = useContext(context || AssIndvRouteNameContext);
  const [showDeliverables, setShowDeliverables] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [{ data: project, loading, error }, fetchProject] = useAxios(
    {
      url: `/ministry_tasks/individual-assignments/${params.id}/`,
      method: "GET",
    },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    if (params.id) {
      fetchProject();
    }
  }, [params.id]);

  useEffect(() => {
    if (project) {
      setCurrentRoute(`${project?.assignment_name}`);
    }
  }, [project]);

  return (
    <div className="mt-5 fill-blueSecondary font-poppins">
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <>
            <button
              className="fixed top-[50%] -right-0 rounded-full bg-[#485FE5] p-2 dark:bg-navy-700"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <IoChevronForward
                className={`h-7 w-7 rotate-180 text-white md:h-10 md:w-10`}
              />
            </button>
            <SidebarModal
              isOpen={showModal}
              setIsOpen={setShowModal}
              content={<AssignmentStatus project={project} />}
              closeBtn={
                <button
                  className={`fixed top-8 left-[85%] z-50 rounded-full p-3 dark:bg-navy-700 md:left-[96%] md:top-6 3xl:top-[48%] 3xl:left-[66%] 3xl:bg-[#485FE5]`}
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <IoChevronBack
                    className={`h-7 w-7 rotate-180 text-gray-800 3xl:h-10 3xl:w-10 3xl:text-white`}
                  />
                </button>
              }
              bg={"bg-[#F5FAFF]"}
            />
            <div className="flex gap-8">
              <BackIcon
                onClick={() => {
                  if (showDeliverables) {
                    setShowDeliverables(false);
                    return;
                  }
                  if (showActivities) {
                    setShowActivities(false);
                    return;
                  }
                  navigate(-1, { replace: true });
                }}
                className="cursor-pointer"
              />
              <div className="mt-4 flex items-start gap-3">
                <div className="h-4 w-4 bg-orange-400" />
                <div>
                  <p className="text-3xl font-semibold text-gray-700 dark:text-white ">
                    Assignment Detail
                  </p>
                  <p className="mt-2 space-x-6 font-semibold tracking-widest text-gray-700 dark:text-white ">
                    ID:
                    <span className="font-normal tracking-widest text-gray-500">
                      #{project?.id}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="gap-2 3xl:flex">
              <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-3 px-4 dark:bg-navy-700">
                {/* Milestones */}
                <MilestoneForm
                  project={project}
                  milestonesData={project?.executing_bodies}
                  fetchMilestones={fetchProject}
                  showActivities={showActivities}
                  setShowActivities={setShowActivities}
                />
              </div>
              {/* <AssignmentStatus project={project} /> */}
            </div>
          </>
        )
      ) : (
        <Error error={error} />
      )}
    </div>
  );
}

export default AssignmentDetails;
