import AddIconFilled from "components/icons/AddIconFilled";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import StatusShow from "components/StatusShow";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import SubDeliverableModal from "views/admin/expert/components/SubDeliverableModal";

function SubDeliverableViewRow({ subDel, isCeo = false }) {
  const [showChileDel, setShowChildDel] = useState(false);
  const [viewForm, setViewForm] = useState(false);

  return (
    <>
      <tr
        key={subDel.id}
        className={`table-row ${
          showChileDel
            ? "bg-[#EDEDFC] dark:bg-navy-900 "
            : "bg-gray-50 dark:bg-navy-900"
        }`}
      >
        <td colSpan={2}>
          <div className={`relative ml-10 flex max-w-[300px]`}>
            <div className=" flex items-center justify-between gap-3 text-start">
              {showChileDel ? (
                <ChevronDownOutline
                  onClick={() => setShowChildDel(!showChileDel)}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              ) : (
                <ChevronRightOutline
                  onClick={() => setShowChildDel(!showChileDel)}
                  className="cursor-pointer fill-[#171C26] dark:fill-white"
                />
              )}
              <p className="dark:text-white">{subDel?.deliverable_name}</p>
            </div>
          </div>
        </td>
        <td>
          <div className="flex justify-center">
            <p className="w-[110px] truncate text-center dark:text-white">
              {subDel?.deliverable_description}
            </p>
          </div>
        </td>
        {!isCeo && (
          <td>
            {viewForm && (
              <SubDeliverableModal
                deliverable={subDel}
                key={subDel?.id}
                setOpen={setViewForm}
              />
            )}
            {subDel?.status !== "completed" && (
              <div
                className="flex cursor-pointer justify-center"
                onClick={() => {
                  setViewForm(true);
                }}
              >
                <AddIconFilled className="h-8 w-8 " />
              </div>
            )}
          </td>
        )}
        <td className="flex justify-center">
          <StatusShow status={subDel?.status} />
        </td>
        <td>
          <div className="flex w-full items-center justify-center gap-1">
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {subDel?.start_date?.substring(0, 10)}
            </p>
            <BsChevronRight size={10} />
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {subDel?.end_date?.substring(0, 10)}
            </p>
          </div>
        </td>
        <td>
          <p className={`text-center dark:text-white`}>
            {subDel?.measurement_unit}
          </p>
        </td>
      </tr>
      {showChileDel &&
        subDel?.sub_deliverables?.map((subDel) => (
          <SubDeliverableViewRow key={subDel.id} subDel={subDel} />
        ))}
    </>
  );
}
export default SubDeliverableViewRow;
