import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://task-backend.erp.mols.gov.et",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // console.log("Auth state", getState().auth);
    const token = getState().auth.accessToken;
    if (token) {
      headers.append("authorization", `JWT ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 401) {
    console.log("sending refresh token");
    // send refresh token to get new access token
    const refreshResult = await baseQuery(
      "/api/v1/jwt/refresh/",
      api,
      extraOptions
    );
    console.log(refreshResult);
    if (refreshResult?.data) {
      console.log("refresh token success", refreshResult.data);
      const user = api.getState().auth.user;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  // console.log("result", result);
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
