import StatusShow from "components/StatusShow";
import { useEffect, useState } from "react";

function MilestoneRowForm({
  showMilestoneForm,
  setShowMilestoneForm,
  handleSave,
  data,
  edit = false,
  setEdit,
}) {
  const [status, setStatus] = useState("not_started");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState("");

  const submit = () => {
    handleSave({
      milestoneName: name,
      milestoneDescription: description,
      milestoneBudget: budget,
      milestoneStartDate: startDate,
      status,
    });
  };

  useEffect(() => {
    if (edit) {
      setName(data?.milestone_name);
      setDescription(data?.milestone_description);
      setBudget(data?.milestone_budget);
      setStartDate(data?.delivery_date);
      setStatus(data?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <form
      className={`${showMilestoneForm ? "relative" : "hidden"} mt-2 pb-14`}
      onSubmit={submit}
    >
      <div className="flex items-start justify-between gap-10 rounded-lg bg-white py-2 px-4 text-gray-700">
        {/* left side */}
        <div className="flex-grow">
          <div className="mt-4 flex items-center justify-between">
            <div>
              <h1>
                Milestone Name <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Write a name</p>
            </div>
            <input
              id="milestoneName"
              type="text"
              placeholder="Milestone Name"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>Milestone Description</h1>
              <p className="text-xs text-gray-500">Short Description</p>
            </div>
            <textarea
              id="milestoneDescription"
              placeholder="Milestone Description"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          {/* <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1 className="">Milestone Budget</h1>
              <p className="text-xs text-gray-500">Write a budget</p>
            </div>
            <input
              id="milestoneBudget"
              type="number"
              pattern="[0-9]*"
              placeholder="Milestone Budget"
              className="w-[55%] rounded-lg  bg-gray-50 py-4 px-2"
              onChange={(e) => setBudget(e.target.value)}
              value={budget}
            />
          </div> */}
        </div>
        {/* Right side */}
        <div className=" flex-grow">
          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>
                Delivery Date <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Type Date</p>
            </div>
            <input
              id="milestoneStartDate"
              type="date"
              placeholder="Start Date"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              required
            />
          </div>

          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>
                Status <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Choose status</p>
            </div>
            <StatusShow status={status || "not_started"} />
          </div>
        </div>
      </div>

      <div className="absolute right-3 bottom-0 flex items-center gap-5">
        <div
          className="flex cursor-pointer items-center gap-1 rounded-md bg-gray-500 py-1.5 px-3 text-white"
          onClick={() => {
            setEdit(null);
            setShowMilestoneForm(false);
          }}
        >
          Cancel
        </div>

        <button
          className=" flex items-center gap-1 rounded-md bg-[#485FE5] py-1.5 px-6 text-white"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
export default MilestoneRowForm;
